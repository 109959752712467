import React, {useEffect} from "react";
import { Logo } from "../../components/Logo";
import "./style.css";
import {Header} from "../../components/Header";
import {Link, useLocation, useNavigate} from "react-router-dom";

const useQuery = () => {
  return new URLSearchParams(useLocation().search);
};

export const Success = () => {
  const navigate = useNavigate()
  const query = useQuery();
  const futaex = query.get("futaex")
  console.log(futaex)
  
  
  useEffect(() => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  }, []);
  const gotoHome = () => {
    navigate("/")
  }
  return (
    <div className="home-screen animate-fadeIn" style={{height: "1603px"}}>
      <div className="form-contact-su">
        <p className="text-wrapper">{futaex ? "Tạo đơn hàng thành công" : "Đặt hàng thành công"}</p>
        <div className="group" onClick={gotoHome}>
          <div className="li-n-h">Về trang chủ</div>
        </div>
        <img className="frame" src="/img/frame.svg"/>
        <img className="layer" src="/img/icon/check_success.png"/>
      </div>
      <Header/>
      <div className="footer" style={{top: "555px"}}>
        <div className="USP">
          <img className="layer-2" alt="Layer" src="/img/layer-3-8.svg"/>
          <div className="giao-h-ng-mi-n-ph">GIAO HÀNG NHANH CHÓNG, TIẾT KIỆM</div>
          <p className="ch-nh-s-ch-i-tr-linh">CHÍNH SÁCH ĐỔI TRẢ LINH HOẠT</p>
          <div className="h-tr">HỖ TRỢ 24/7</div>
          <p className="text-wrapper-29">Hỗ trợ bà con nông dân giao hàng nhanh chóng khắp cả nước.</p>
          <p className="text-wrapper-30">Giúp bà con nông dân và người tiêu dùng đổi trả thuận tiện hơn</p>
          <p className="text-wrapper-31">Đội ngũ hỗ trợ bà con 24/7 nhanh chóng, tức thời</p>
          <img className="layer-3" alt="Layer" src="/img/icon/flex_change.png"/>
          <img className="layer-4" alt="Layer" src="/img/icon/support.png"/>
        </div>
        <div className="footer-2">
          <Link className="overlap-group-wrapper" to="/home">
            <div className="overlap-group-12">
              <Logo className="logo-3" divClassName="logo-2" divClassNameOverride="logo-2"/>
              <img className="grocery-cart-white" alt="Grocery cart white" src="/img/grocery-cart-white-1.png"/>
            </div>
          </Link>
          <div className="overlap-9">
            <p className="text-wrapper-32">
              Funong.vn là cầu nối giúp bà con nông dân có thể đưa sản phẩm của mình tới tay người tiêu dùng một cách dễ
              dàng và thuận tiện. Bà con nông dân đỡ được nỗi lo về chi phí vận chuyển cũng như chi phí bán hàng mà vẫn
              đạt được hiệu quả cao nhất
            </p>
            <p className="text-wrapper-33">223 Trần Hưng Đạo, Quận 1, TPHCM</p>
          </div>
          <img className="layer-5" alt="Layer" src="/img/icon/location.png"/>
          <img className="layer-6" alt="Layer" src="/img/icon/phone.png"/>
          <div className="text-wrapper-34">1900 6067</div>
          <div className="flexcontainer">
            <p className="text-i">
              <span className="text-wrapper-35">HỖ TRỢ KHÁCH HÀNG</span>
              <span className="text-wrapper-36">
                &nbsp;&nbsp; <br/>
              </span>
            </p>
            <p className="text-i">
              <span className="text-wrapper-36">
                Chính sách giao hàng&nbsp;&nbsp;&nbsp;&nbsp; <br/>
              </span>
            </p>
            <p className="text-i">
              <span className="text-wrapper-36">
                Chính sách đổi trả&nbsp;&nbsp;&nbsp;&nbsp; <br/>
              </span>
            </p>
            <p className="text-i">
              <span className="text-wrapper-36">
                Chính sách thành viên&nbsp;&nbsp;&nbsp;&nbsp;
                <br/>
              </span>
            </p>
            <p className="text-i">
              <span className="text-wrapper-36">
                Hướng dẫn mua hàng và hoàn tiền&nbsp;&nbsp;&nbsp;&nbsp; <br/>
              </span>
            </p>
            <p className="text-i">
              <span className="text-wrapper-36">
                Chính sách xuất hóa đơn đỏ&nbsp;&nbsp;&nbsp;&nbsp; <br/>
              </span>
            </p>
            <p className="text-i">
              <span className="text-wrapper-36">Giấy chứng nhận Vệ sinh ATTP</span>
            </p>
          </div>
          <div className="i-t-c-v-n-chuy-n">ĐỐI TÁC VẬN CHUYỂN</div>
          <div className="i-t-c-thanh-to-n">ĐỐI TÁC THANH TOÁN</div>
          <div className="group-6">
            <div className="goo-pay">GOOPAY</div>
            <img className="layer-7" alt="Layer" src="/img/icon/partner_gp.png"/>
          </div>
          <div className="flexcontainer-i">
            <p className="text-i">
              <span className="text-wrapper-36">
                <br/>
              </span>
            </p>
            <p className="text-i">
              <span className="text-wrapper-36">
                <br/>
              </span>
            </p>
            <p className="text-i">
              <span className="text-wrapper-36">
                Hướng dẫn mua hàng <br/>
              </span>
            </p>
            <p className="text-i">
              <span className="text-wrapper-36">
                Hóa đơn điện tử <br/>
              </span>
            </p>
            <p className="text-i">
              <span className="text-wrapper-36">
                Chính sách khách hàng <br/>
              </span>
            </p>
            <p className="text-i">
              <span className="text-wrapper-36">
                Tích điểm <br/>
              </span>
            </p>
            <p className="text-i">
              <span className="text-wrapper-36">Cảnh báo giả mạo</span>
            </p>
          </div>
          <div className="img-wrapper">
            <img className="image-10" alt="Image" src="/img/image-13.png"/>
          </div>
          <img className="image-11" alt="Image" src="/img/image-12.png"/>
        </div>
      </div>
    </div>
  
  );
};
