import React, {useEffect} from "react";
import "./style.css";

export const Popup = ({closePopup}) => {
  
  useEffect(() => {
    const el = document.getElementsByTagName("html")?.[0]
    if(el) {
      el.style.overflow = "hidden";
    }
    return () => {
      const el = document.getElementsByTagName("html")?.[0]
      if(el) {
        el.style.overflow = "auto";
      }
    }
  }, []);
  
  
  return (
    <div className="popup animate-fadeIn">
      <img className="icon-close" src="/img/icon-close.svg" onClick={closePopup}/>
      <div className="group" onClick={closePopup}>
        <div className="li-n-h">Đóng</div>
      </div>
      <div className="overlap">
        <img className="img" src="/img/group-12.png"/>
        <img className="vector" src="/img/vector.svg"/>
        <p className="text-wrapper">12 Ấp 3, Xã Cái Răng, Cần Thơ</p>
        <div className="div">0903 123 456</div>
      </div>
      <div className="overlap-group">
        <div className="group-2">
          <p className="p">Cửa hàng chú Tám Cần Thơ</p>
          <div className="overlap-group-2">
            <p className="text-wrapper-2">
              Chuyên cung cấp gạo và trái cây sạch chất lượng cao, được nhiều người tin dùng
            </p>
            <div className="rate">
              <img className="star" src="/img/star-2.svg"/>
              <img className="star-2" src="/img/star-3.svg"/>
              <img className="star-3" src="/img/star-4.svg"/>
              <img className="star-4" src="/img/star-5.svg"/>
              <img className="star-5" src="/img/star-6.svg"/>
            </div>
          </div>
        </div>
        <img className="layer" src="/img/layer-3.svg"/>
        <div className="text-wrapper-3">150 lượt theo dõi</div>
        <div className="text-wrapper-4">Xem cửa hàng</div>
        <img className="layer-2" src="/img/layer-3-1.svg"/>
      </div>
      <img className="mask-group" src="/img/mask-group.png"/>
    </div>
  );
};
