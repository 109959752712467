import React, {useEffect} from "react";
import {Logo} from "../../components/Logo";
import "./style.css";
import {Header} from "../../components/Header";
import {Link, useNavigate} from "react-router-dom";

export const LienHeFutaex = () => {
  const navigate = useNavigate();
  useEffect(() => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  }, []);
  const gotoSuccess = () => {
    navigate("/success?futaex=1");
  }
  return (
    <div className="home-screen animate-fadeIn" style={{height: "1603px"}}>
      <div className="form-contact" style={{paddingBottom: "80px"}}>
        <div className="overlap">
          <div className="overlap-group">
            <div className="text-wrapper">Tạo đơn hàng mới</div>
            <div className="div">Thông tin người gửi</div>
            <div className="text-wrapper-2">Thông tin người nhận</div>
            <div className="text-wrapper-3">Thông tin kiện hàng</div>
            <div className="group">
              <div className="rectangle"></div>
              <img className="vector" src="/img/vector-4.svg"/>
            </div>
            <div className="rectangle-wrapper">
              <div className="rectangle-2"></div>
            </div>
            <div className="div-wrapper">
              <div className="rectangle-2"></div>
            </div>
            <div className="group-2">
              <div className="rectangle-2"></div>
            </div>
            <div className="group-3">
              <div className="rectangle-3"></div>
            </div>
            <div className="group-4">
              <div className="rectangle-4"></div>
            </div>
            <div className="group-5">
              <div className="rectangle-4"></div>
            </div>
            <div className="group-6">
              <div className="rectangle-4"></div>
            </div>
            <div className="group-7">
              <div className="rectangle-2"></div>
            </div>
            <div className="group-8">
              <div className="rectangle-2"></div>
            </div>
            <div className="group-9">
              <div className="rectangle-5"></div>
            </div>
            <div className="group-10">
              <div className="rectangle-6"></div>
            </div>
            <div className="group-11">
              <div className="rectangle-7"></div>
            </div>
            <div className="group-12">
              <div className="rectangle-8"></div>
              <img className="img" src="/img/vector-1.svg"/>
            </div>
            <div className="text-wrapper-4">Tỉnh/ Thành phố</div>
            <div className="text-wrapper-5">Quận/ Huyện</div>
            <div className="text-wrapper-6">Phường/ Xã</div>
            <p className="kh-i-l-ng-kg"><span className="span">Khối lượng (kg) </span> <span
              className="text-wrapper-7">*</span></p>
            <div className="text-wrapper-8">Kích thước (cm)</div>
            <p className="p">Vui lòng nhập họ và tên người nhận</p>
            <p className="text-wrapper-9">Nhập địa chỉ người nhận</p>
            <div className="text-wrapper-10">Tỉnh/ Thành...</div>
            <div className="text-wrapper-11">Quận/ Huyện...</div>
            <div className="text-wrapper-12">Phường/ Xã...</div>
            <img className="vector-2" src="/img/vector-4.svg"/>
            <p className="text-wrapper-13">Vui lòng nhập số điện thoại người nhận</p>
            <div className="text-wrapper-14">Dài</div>
            <div className="text-wrapper-15">Rộng</div>
            <p className="text-wrapper-16">Futa Express sẽ lấy hàng tận nơi theo địa chỉ người gửi</p>
            <img className="line" src="/img/line-7.svg"/>
            <div className="frame">
              <div className="text-wrapper-17">Tạm tính</div>
              <p className="kh-i-l-ng-ki-n-h-ng">
                <span className="text-wrapper-18">Khối lượng kiện hàng 1<br/></span>
                <span className="text-wrapper-19">0 kg</span>
              </p>
              <p className="c-c-ch-nh">
                <span className="text-wrapper-18">Cước chính<br/></span> <span className="text-wrapper-19">0 đ</span>
              </p>
              <p className="t-ng-ki-n">
                <span className="text-wrapper-18">Tổng kiện 1<br/></span> <span className="text-wrapper-20">0 đ</span>
              </p>
            </div>
            <img className="line-2" src="/img/line-8.svg"/>
            <p className="t-ng-i-h-ng-ho-v">
              <span className="span">Tổng đài hàng hoá và CSKH:&nbsp;&nbsp; </span>
              <span className="text-wrapper-21">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </span>
              <span className="text-wrapper-22">1900 6767</span>
            </p>
            <img className="image" src="/img/image-20.png"/>
            <div className="rectangle-9"></div>
          </div>
          <img className="layer" src="/img/layer-3.svg"/>
          <img className="group-13" src="/img/group-14.png"/>
          <img className="group-14" src="/img/group-17.png"/>
          <div className="overlap-2">
            <div className="group-15">
              <div className="rectangle-10"></div>
            </div>
            <div className="text-wrapper-23">Cao</div>
          </div>
          <div className="text-wrapper-24">Thông tin người gửi</div>
          <p className="h-v-t-n"><span className="span">Họ và Tên </span> <span className="text-wrapper-7">*</span></p>
          <p className="h-nh-ki-n-h-ng"><span className="span">Hình kiện hàng </span> <span
            className="text-wrapper-7">*</span></p>
          <div className="text-wrapper-25">Tối đa 5 hình</div>
          <div className="text-wrapper-26">Loại sản phẩm</div>
          <div className="text-wrapper-27">Giá trị hàng (VND)</div>
          <div className="text-wrapper-28">Địa chỉ</div>
          <p className="i-n-tho-i"><span className="span">Điện thoại </span> <span className="text-wrapper-7">*</span>
          </p>
          <p className="t-n-h-ng-ho"><span className="span">Tên hàng hoá </span> <span
            className="text-wrapper-7">*</span></p>
          <div className="text-wrapper-29">Hình thức giao nhận</div>
          <p className="text-wrapper-30">Nhận tận nơi, giao tận nơi</p>
          <div className="text-wrapper-31">Chọn loại sản phâm</div>
          <div className="text-wrapper-32">0</div>
          <img className="vector-3" src="/img/vector-4.svg"/>
          <img className="vector-4" src="/img/vector-4.svg"/>
          <p className="text-wrapper-33">Nhập mô tả hàng hoá</p>
          <div className="text-wrapper-34">0</div>
          <div className="frame-2">
            <div className="text-wrapper-35">Nhập địa chỉ</div>
          </div>
          <div className="overlap-3">
            <div className="text-wrapper-36">+</div>
          </div>
        </div>
        <div className="li-n-h-wrapper">
          <div className="li-n-h">Tạo đơn</div>
        </div>
      </div>
      <Header/>
      <div className="footer" style={{top: "1038px"}}>
        <div className="USP">
          <img className="layer-2" alt="Layer" src="/img/layer-3-8.svg"/>
          <div className="giao-h-ng-mi-n-ph">GIAO HÀNG NHANH CHÓNG, TIẾT KIỆM</div>
          <p className="ch-nh-s-ch-i-tr-linh">CHÍNH SÁCH ĐỔI TRẢ LINH HOẠT</p>
          <div className="h-tr">HỖ TRỢ 24/7</div>
          <p className="text-wrapper-29">Hỗ trợ bà con nông dân giao hàng nhanh chóng khắp cả nước.</p>
          <p className="text-wrapper-30">Giúp bà con nông dân và người tiêu dùng đổi trả thuận tiện hơn</p>
          <p className="text-wrapper-31">Đội ngũ hỗ trợ bà con 24/7 nhanh chóng, tức thời</p>
          <img className="layer-3" alt="Layer" src="/img/icon/flex_change.png"/>
          <img className="layer-4" alt="Layer" src="/img/icon/support.png"/>
        </div>
        <div className="footer-2">
          <Link className="overlap-group-wrapper" to="/home">
            <div className="overlap-group-12">
              <Logo className="logo-3" divClassName="logo-2" divClassNameOverride="logo-2"/>
              <img className="grocery-cart-white" alt="Grocery cart white" src="/img/grocery-cart-white-1.png"/>
            </div>
          </Link>
          <div className="overlap-9">
            <p className="text-wrapper-32">
              Funong.vn là cầu nối giúp bà con nông dân có thể đưa sản phẩm của mình tới tay người tiêu dùng một cách dễ
              dàng và thuận tiện. Bà con nông dân đỡ được nỗi lo về chi phí vận chuyển cũng như chi phí bán hàng mà vẫn
              đạt được hiệu quả cao nhất
            </p>
            <p className="text-wrapper-33">223 Trần Hưng Đạo, Quận 1, TPHCM</p>
          </div>
          <img className="layer-5" alt="Layer" src="/img/icon/location.png"/>
          <img className="layer-6" alt="Layer" src="/img/icon/phone.png"/>
          <div className="text-wrapper-34">1900 6067</div>
          <div className="flexcontainer">
            <p className="text-i">
              <span className="text-wrapper-35">HỖ TRỢ KHÁCH HÀNG</span>
              <span className="text-wrapper-36">
                &nbsp;&nbsp; <br/>
              </span>
            </p>
            <p className="text-i">
              <span className="text-wrapper-36">
                Chính sách giao hàng&nbsp;&nbsp;&nbsp;&nbsp; <br/>
              </span>
            </p>
            <p className="text-i">
              <span className="text-wrapper-36">
                Chính sách đổi trả&nbsp;&nbsp;&nbsp;&nbsp; <br/>
              </span>
            </p>
            <p className="text-i">
              <span className="text-wrapper-36">
                Chính sách thành viên&nbsp;&nbsp;&nbsp;&nbsp;
                <br/>
              </span>
            </p>
            <p className="text-i">
              <span className="text-wrapper-36">
                Hướng dẫn mua hàng và hoàn tiền&nbsp;&nbsp;&nbsp;&nbsp; <br/>
              </span>
            </p>
            <p className="text-i">
              <span className="text-wrapper-36">
                Chính sách xuất hóa đơn đỏ&nbsp;&nbsp;&nbsp;&nbsp; <br/>
              </span>
            </p>
            <p className="text-i">
              <span className="text-wrapper-36">Giấy chứng nhận Vệ sinh ATTP</span>
            </p>
          </div>
          <div className="i-t-c-v-n-chuy-n">ĐỐI TÁC VẬN CHUYỂN</div>
          <div className="i-t-c-thanh-to-n">ĐỐI TÁC THANH TOÁN</div>
          <div className="group-6">
            <div className="goo-pay">GOOPAY</div>
            <img className="layer-7" alt="Layer" src="/img/icon/partner_gp.png"/>
          </div>
          <div className="flexcontainer-i">
            <p className="text-i">
              <span className="text-wrapper-36">
                <br/>
              </span>
            </p>
            <p className="text-i">
              <span className="text-wrapper-36">
                <br/>
              </span>
            </p>
            <p className="text-i">
              <span className="text-wrapper-36">
                Hướng dẫn mua hàng <br/>
              </span>
            </p>
            <p className="text-i">
              <span className="text-wrapper-36">
                Hóa đơn điện tử <br/>
              </span>
            </p>
            <p className="text-i">
              <span className="text-wrapper-36">
                Chính sách khách hàng <br/>
              </span>
            </p>
            <p className="text-i">
              <span className="text-wrapper-36">
                Tích điểm <br/>
              </span>
            </p>
            <p className="text-i">
              <span className="text-wrapper-36">Cảnh báo giả mạo</span>
            </p>
          </div>
          <div className="img-wrapper">
            <img className="image-10" alt="Image" src="/img/image-13.png"/>
          </div>
          <img className="image-11" alt="Image" src="/img/image-12.png"/>
        </div>
      </div>
    </div>
  );
};
