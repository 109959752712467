/*
We're constantly improving the code you see. 
Please share your feedback here: https://form.asana.com/?k=uvp-HPgd3_hyoXRBw1IcNg&d=1152665201300829
*/

import PropTypes from "prop-types";
import React from "react";
import { useReducer } from "react";
import { Btn } from "../Btn";
import "./style.css";
import {Link} from "react-router-dom";

export const Product = ({
  id,
  property1,
  className,
  image = "/img/image-coconut.png",
  text = "Dừa Bến Tre",
  text1 = "Dừa Tươi giàu dinh dưỡng, vừa tốt cho sức khỏe, tươi mát",
  text2 = "15.000đ",
}) => {
  const [state, dispatch] = useReducer(reducer, {
    property1: property1 || "default",
  });
  
  return (
    <Link to={`/san-pham?id=${id}`}>
      <div
        className={`product property-1-${state.property1} ${className}`}
        onMouseLeave={() => {
          dispatch("mouse_leave");
        }}
        onMouseEnter={() => {
          dispatch("mouse_enter");
        }}
      >
        <img className="image" alt="Image" src={state.property1 === "variant-2" ? image : image}/>
        <div className="d-a-b-n-tre">{text}</div>
        <p className="d-a-t-i-gi-u-dinh-d">{text1}</p>
        <div className="element">{text2}</div>
        <img className="star" alt="Star" src="/img/star-6-7.svg"/>
        <img className="star-2" alt="Star" src="/img/star-6-7.svg"/>
        <img className="star-3" alt="Star" src="/img/star-6-7.svg"/>
        <img className="star-4" alt="Star" src="/img/star-6-7.svg"/>
        <img className="star-5" alt="Star" src="/img/star-6-7.svg"/>
        <Btn className="btn-instance" text="Mua ngay"/>
      </div>
    </Link>
  );
};

function reducer(state, action) {
  switch (action) {
    case "mouse_enter":
      return {
        ...state,
        property1: "variant-2",
      };
    
    case "mouse_leave":
      return {
        ...state,
        property1: "default",
      };
  }
  
  return state;
}

Product.propTypes = {
  property1: PropTypes.oneOf(["variant-2", "default"]),
  image: PropTypes.string,
  text: PropTypes.string,
  text1: PropTypes.string,
  text2: PropTypes.string,
};
