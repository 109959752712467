import React, {useEffect} from "react";
import "./style.css";
import {Link, useNavigate} from "react-router-dom";

export const Shop = () => {
  
  const navigate = useNavigate()
  useEffect(() => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  }, []);
  const gotoProduct = () => {
    navigate("/san-pham")
  }
  
  return (
    <div className="shop animate-fadeIn">
      <div className="site-heading">
        <div className="heading">
          <div className="overlap-group">
            <button className="btn">
              <button className="button">Tìm kiếm</button>
            </button>
          </div>
          <img className="icon-gio-hang" src="/img/icon-gio-hang-1.svg"/>
          <img className="icon-love" src="/img/icon-love-1.svg"/>
          <img className="icon-user" src="/img/icon-user-1.svg"/>
          <div className="logo">
            <img className="img" src="/img/grocery-cart-1-2.png"/>
            <div className="text-wrapper">Funong</div>
            <div className="div">.vn</div>
          </div>
        </div>
        <div className="topheading">
          
          <div className="p marquee">
            <span>Phiên bản thử nghiệm</span>
          </div>
          
          <p className="hotline"><span className="span">Hotline: </span> <span
            className="text-wrapper-2">1900 6067</span></p>
          <img className="icon-phone" src="/img/icon-phone-1.svg"/>
        </div>
        <div className="main-nav">
          <img className="icon-gift" src="/img/icon-gift-1.svg"/>
          <div className="text-wrapper-3">Khuyến mãi HOT</div>
          <Link to="/">
            <div className="home">
              <img className="icon-home" src="/img/icon-home-2.svg"/>
              <div className="text-wrapper-4">Trang chủ</div>
            </div>
          </Link>
          
          <Link to="/cua-hang">
            <div className="sanpham">
              <div className="text-wrapper-4">Sản phẩm</div>
              <img className="icon-sanpham" src="/img/icon-sanpham-3.svg"/>
            </div>
          </Link>
          <Link to={"/nha-cung-cap"}>
          <div className="nha-cung-cap">
            <div className="text-wrapper-4">Nhà cung cấp</div>
            <div className="icon-NCC">
              <div className="overlap-group-3">
                <img className="vector-6" src="/img/vector-16.svg"/>
                <img className="vector-7" src="/img/vector-13.svg"/>
                <img className="vector-8" src="/img/vector-31.svg"/>
                <img className="vector-9" src="/img/vector-32.svg"/>
              </div>
            </div>
          </div>
          </Link>
          <div className="lien-he">
            <div className="text-wrapper-4">Liên hệ</div>
            <img className="vector-10" src="/img/vector-11.svg"/>
          </div>
          <Link to={"/lien-he-futaex"}>
          <div className="contact-futaex">
              <div className="overlap"><img className="image" src="/img/image-13-1.png"/></div>
              <div className="text-wrapper-5">Liên hệ với</div>
              <img className="polygon" src="/img/polygon-4.svg"/>
            </div>
          </Link>
        </div>
      </div>
      <div className="frame">
        <div className="top-ncc">
          <div className="group-shop">
            <p className="text-wrapper-6">Nông sản cô Ba Miền Tây</p>
            <div className="overlap-group-4">
              <p className="text-wrapper-7">
                Chuyên cung cấp hàng nông sản chất lượng cao, xuất khảu sang các nước Châu Âu và Châu Phi
              </p>
              <div className="rate">
                <img className="star" src="/img/star-2-4.svg"/>
                <img className="star-2" src="/img/star-3-4.svg"/>
                <img className="star-3" src="/img/star-4-4.svg"/>
                <img className="star-4" src="/img/star-5-4.svg"/>
                <img className="star-5" src="/img/star-6-4.svg"/>
              </div>
            </div>
          </div>
          <img className="mask-group" src="/img/mask-group-1.png"/>
        </div>
        <div className="frame-2">
          <img className="layer" src="/img/icon/location1.png"/>
          <img className="vector-11" src="/img/icon/phone1.png"/>
          <img className="layer-2" src="/img/icon/heart1.png"/>
          <p className="text-wrapper-8">12 Ấp 3, Xã Cái Răng, Cần Thơ</p>
          <div className="text-wrapper-9">0903 123 456</div>
          <div className="text-wrapper-10">150 lượt theo dõi</div>
          <img className="line" src="/img/line-6.svg"/>
          <img className="layer-3" src="/img/layer-3-8.svg"/>
          <div className="text-wrapper-11">+</div>
          <div className="text-wrapper-12">Theo dõi</div>
        </div>
        <p className="em-s-n-ph-m-ch-t-l">ĐEM SẢN PHẨM CHẤT LƯỢNG NHẤT<br/>ĐẾN BÀ CON NGƯỜI TIÊU DÙNG</p>
      </div>
      <div className="hang-nong-san">
        <div className="h-ng-n-ng-s-n-wrapper">
          <div className="text-wrapper-13">HÀNG NÔNG SẢN</div>
        </div>
        <div className="product" onClick={gotoProduct}>
          <img className="image-2" src="/img/image-coconut.png"/>
          <div className="text-wrapper-14">Dừa Bến Tre</div>
          <p className="text-wrapper-15">Dừa Tươi giàu dinh dưỡng, vừa tốt cho sức khỏe, tươi mát</p>
          <div className="text-wrapper-16">15.000đ</div>
          <img className="star-6" src="/img/star-6-1.svg"/>
          <img className="star-7" src="/img/star-6-1.svg"/>
          <img className="star-8" src="/img/star-6-1.svg"/>
          <img className="star-9" src="/img/star-6-1.svg"/>
          <img className="star-10" src="/img/star-6-1.svg"/>
          <button className="button-wrapper">
            <button className="button">Mua ngay</button>
          </button>
        </div>
        <div className="product-2" onClick={gotoProduct}>
          <img className="image-2" src="/img/image-7-17.png"/>
          <div className="text-wrapper-14">Bí đỏ hồ lô</div>
          <p className="text-wrapper-15">Bí đỏ hồ lô dẻo, ngon, chất lượng, giàu dinh dưỡng</p>
          <div className="text-wrapper-16">8.000đ</div>
          <img className="star-6" src="/img/star-6-1.svg"/>
          <img className="star-7" src="/img/star-6-1.svg"/>
          <img className="star-8" src="/img/star-6-1.svg"/>
          <img className="star-9" src="/img/star-6-1.svg" s/>
          <img className="star-10" src="/img/star-6-1.svg"/>
          <button className="button-wrapper">
            <button className="button">Mua ngay</button>
          </button>
        </div>
        <div className="product-3" onClick={gotoProduct}>
          <img className="image-2" src="/img/image-coconut.png"/>
          <div className="text-wrapper-14">Gạo ST 25</div>
          <p className="text-wrapper-15">Gạo ST25 trở nên đặc biệt là nhờ vào thành phần dinh dưỡng trong từng hạt
            gạo</p>
          <div className="text-wrapper-16">12.000đ</div>
          <img className="star-6" src="/img/star-6-1.svg"/>
          <img className="star-7" src="/img/star-6-1.svg"/>
          <img className="star-8" src="/img/star-6-1.svg"/>
          <img className="star-9" src="/img/star-6-1.svg"/>
          <img className="star-10" src="/img/star-6-1.svg"/>
          <button className="button-wrapper">
            <button className="button">Mua ngay</button>
          </button>
        </div>
        <div className="product-4" onClick={gotoProduct}>
          <img className="image-2" src="/img/image-7-19.png"/>
          <div className="text-wrapper-14">Hành lá</div>
          <p className="text-wrapper-15">Hành lá – loại rau gia vị vô cùng bổ dưỡng</p>
          <div className="text-wrapper-16">10.000đ</div>
          <img className="star-6" src="/img/star-6-1.svg"/>
          <img className="star-7" src="/img/star-6-1.svg"/>
          <img className="star-8" src="/img/star-6-1.svg"/>
          <img className="star-9" src="/img/star-6-1.svg"/>
          <img className="star-10" src="/img/star-6-1.svg"/>
          <button className="button-wrapper">
            <button className="button">Mua ngay</button>
          </button>
        </div>
        <div className="product-5" onClick={gotoProduct}>
          <img className="image-2" src="/img/image-7-20.png"/>
          <div className="text-wrapper-14">Bắp cải trắng</div>
          <p className="text-wrapper-15">Bắp cải là loại rau bổ dưỡng vừa tốt cho sức khỏe, chứa nhiều vitamin C,
            kali</p>
          <div className="text-wrapper-16">15.000đ</div>
          <img className="star-6" src="/img/star-6-1.svg"/>
          <img className="star-7" src="/img/star-6-1.svg"/>
          <img className="star-8" src="/img/star-6-1.svg"/>
          <img className="star-9" src="/img/star-6-1.svg"/>
          <img className="star-10" src="/img/star-6-1.svg"/>
          <button className="button-wrapper">
            <button className="button">Mua ngay</button>
          </button>
        </div>
        <div className="xem-them">
          <div className="text-wrapper-17">Xem thêm</div>
          <img className="polygon-2" src="/img/polygon-2-1.svg"/>
        </div>
      </div>
      <div className="hang-nong-san-2">
        <div className="c-s-n-wrapper">
          <div className="text-wrapper-13">ĐẶC SẢN</div>
        </div>
        <div className="product" onClick={gotoProduct}>
          <img className="image-2" src="/img/image-coconut.png"/>
          <div className="text-wrapper-14">Dừa Bến Tre</div>
          <p className="text-wrapper-15">Dừa Tươi giàu dinh dưỡng, vừa tốt cho sức khỏe, tươi mát</p>
          <div className="text-wrapper-16">15.000đ</div>
          <img className="star-6" src="/img/star-6-1.svg"/>
          <img className="star-7" src="/img/star-6-1.svg"/>
          <img className="star-8" src="/img/star-6-1.svg"/>
          <img className="star-9" src="/img/star-6-1.svg"/>
          <img className="star-10" src="/img/star-6-1.svg"/>
          <button className="button-wrapper">
            <button className="button">Mua ngay</button>
          </button>
        </div>
        <div className="product-2" onClick={gotoProduct}>
          <img className="image-2" src="/img/image-7-17.png"/>
          <div className="text-wrapper-14">Bí đỏ hồ lô</div>
          <p className="text-wrapper-15">Bí đỏ hồ lô dẻo, ngon, chất lượng, giàu dinh dưỡng</p>
          <div className="text-wrapper-16">8.000đ</div>
          <img className="star-6" src="/img/star-6-1.svg"/>
          <img className="star-7" src="/img/star-6-1.svg"/>
          <img className="star-8" src="/img/star-6-1.svg"/>
          <img className="star-9" src="/img/star-6-1.svg"/>
          <img className="star-10" src="/img/star-6-1.svg"/>
          <button className="button-wrapper">
            <button className="button">Mua ngay</button>
          </button>
        </div>
        <div className="product-3" onClick={gotoProduct}>
          <img className="image-2" src="/img/image-7-18.png"/>
          <div className="text-wrapper-14">Dưa hấu Long An</div>
          <p className="text-wrapper-15">Vị thuốc giải nhiệt ngày hè. Dưa vỏ xanh mỏng, cuống nhỏ, mọng nước, thanh
            mát</p>
          <div className="text-wrapper-16">12.000đ</div>
          <img className="star-6" src="/img/star-6-1.svg"/>
          <img className="star-7" src="/img/star-6-1.svg"/>
          <img className="star-8" src="/img/star-6-1.svg"/>
          <img className="star-9" src="/img/star-6-1.svg"/>
          <img className="star-10" src="/img/star-6-1.svg"/>
          <button className="button-wrapper">
            <button className="button">Mua ngay</button>
          </button>
        </div>
        <div className="product-4" onClick={gotoProduct}>
          <img className="image-2" src="/img/image-7-19.png"/>
          <div className="text-wrapper-14">Hành lá</div>
          <p className="text-wrapper-15">Hành lá – loại rau gia vị vô cùng bổ dưỡng</p>
          <div className="text-wrapper-16">10.000đ</div>
          <img className="star-6" src="/img/star-6-1.svg"/>
          <img className="star-7" src="/img/star-6-1.svg"/>
          <img className="star-8" src="/img/star-6-1.svg"/>
          <img className="star-9" src="/img/star-6-1.svg"/>
          <img className="star-10" src="/img/star-6-1.svg"/>
          <button className="button-wrapper">
            <button className="button">Mua ngay</button>
          </button>
        </div>
        <div className="product-5" onClick={gotoProduct}>
          <img className="image-2" src="/img/image-7-20.png"/>
          <div className="text-wrapper-14">Bắp cải trắng</div>
          <p className="text-wrapper-15">Bắp cải là loại rau bổ dưỡng vừa tốt cho sức khỏe, chứa nhiều vitamin C,
            kali</p>
          <div className="text-wrapper-16">15.000đ</div>
          <img className="star-6" src="/img/star-6-1.svg"/>
          <img className="star-7" src="/img/star-6-1.svg"/>
          <img className="star-8" src="/img/star-6-1.svg"/>
          <img className="star-9" src="/img/star-6-1.svg"/>
          <img className="star-10" src="/img/star-6-1.svg"/>
          <button className="button-wrapper">
            <button className="button">Mua ngay</button>
          </button>
        </div>
        <div className="xem-them-2">
          <div className="text-wrapper-17">Xem thêm</div>
          <img className="polygon-2" src="/img/polygon-2-1.svg"/>
        </div>
      </div>
      <div className="trai-cay-tuoi">
        <div className="tr-i-c-y-t-i-wrapper">
          <div className="text-wrapper-13">TRÁI CÂY TƯƠI</div>
        </div>
        <div className="product-6" onClick={gotoProduct}>
          <img className="image-2" src="/img/image-coconut.png"/>
          <div className="text-wrapper-14">Dừa Bến Tre</div>
          <p className="text-wrapper-15">Dừa Tươi giàu dinh dưỡng, vừa tốt cho sức khỏe, tươi mát</p>
          <div className="text-wrapper-16">15.000đ</div>
          <img className="star-6" src="/img/star-6-1.svg"/>
          <img className="star-7" src="/img/star-6-1.svg"/>
          <img className="star-8" src="/img/star-6-1.svg"/>
          <img className="star-9" src="/img/star-6-1.svg"/>
          <img className="star-10" src="/img/star-6-1.svg"/>
          <button className="button-wrapper">
            <button className="button">Mua ngay</button>
          </button>
        </div>
        <div className="product-2" onClick={gotoProduct}>
          <img className="image-2" src="/img/image-7-17.png"/>
          <div className="text-wrapper-14">Bí đỏ hồ lô</div>
          <p className="text-wrapper-15">Bí đỏ hồ lô dẻo, ngon, chất lượng, giàu dinh dưỡng</p>
          <div className="text-wrapper-16">8.000đ</div>
          <img className="star-6" src="/img/star-6-1.svg"/>
          <img className="star-7" src="/img/star-6-1.svg"/>
          <img className="star-8" src="/img/star-6-1.svg"/>
          <img className="star-9" src="/img/star-6-1.svg"/>
          <img className="star-10" src="/img/star-6-1.svg"/>
          <button className="button-wrapper">
            <button className="button">Mua ngay</button>
          </button>
        </div>
        <div className="product-3" onClick={gotoProduct}>
          <img className="image-2" src="/img/image-7-18.png"/>
          <div className="text-wrapper-14">Dưa hấu Long An</div>
          <p className="text-wrapper-15">Vị thuốc giải nhiệt ngày hè. Dưa vỏ xanh mỏng, cuống nhỏ, mọng nước, thanh
            mát</p>
          <div className="text-wrapper-16">12.000đ</div>
          <img className="star-6" src="/img/star-6-1.svg"/>
          <img className="star-7" src="/img/star-6-1.svg"/>
          <img className="star-8" src="/img/star-6-1.svg"/>
          <img className="star-9" src="/img/star-6-1.svg"/>
          <img className="star-10" src="/img/star-6-1.svg"/>
          <button className="button-wrapper">
            <button className="button">Mua ngay</button>
          </button>
        </div>
        <div className="product-4" onClick={gotoProduct}>
          <img className="image-2" src="/img/image-7-19.png"/>
          <div className="text-wrapper-14">Hành lá</div>
          <p className="text-wrapper-15">Hành lá – loại rau gia vị vô cùng bổ dưỡng</p>
          <div className="text-wrapper-16">10.000đ</div>
          <img className="star-6" src="/img/star-6-1.svg"/>
          <img className="star-7" src="/img/star-6-1.svg"/>
          <img className="star-8" src="/img/star-6-1.svg"/>
          <img className="star-9" src="/img/star-6-1.svg"/>
          <img className="star-10" src="/img/star-6-1.svg"/>
          <button className="button-wrapper">
            <button className="button">Mua ngay</button>
          </button>
        </div>
        <div className="xem-them-3">
          <div className="text-wrapper-17">Xem thêm</div>
          <img className="polygon-2" src="/img/polygon-2-1.svg"/>
        </div>
        <div className="overlap-2">
          <div className="text-wrapper-18">50%</div>
          <div className="text-wrapper-19">Giảm đến</div>
        </div>
      </div>
      <div className="trai-cay-tuoi-2">
        <div className="thu-h-i-s-n-wrapper">
          <div className="text-wrapper-13">THUỶ HẢI SẢN</div>
        </div>
        <div className="product" onClick={gotoProduct}>
          <img className="image-2" src="/img/image-coconut.png"/>
          <div className="text-wrapper-14">Dừa Bến Tre</div>
          <p className="text-wrapper-15">Dừa Tươi giàu dinh dưỡng, vừa tốt cho sức khỏe, tươi mát</p>
          <div className="text-wrapper-16">15.000đ</div>
          <img className="star-6" src="/img/star-6-1.svg"/>
          <img className="star-7" src="/img/star-6-1.svg"/>
          <img className="star-8" src="/img/star-6-1.svg"/>
          <img className="star-9" src="/img/star-6-1.svg"/>
          <img className="star-10" src="/img/star-6-1.svg"/>
          <button className="button-wrapper">
            <button className="button">Mua ngay</button>
          </button>
        </div>
        <div className="product-2" onClick={gotoProduct}>
          <img className="image-2" src="/img/image-7-17.png"/>
          <div className="text-wrapper-14">Bí đỏ hồ lô</div>
          <p className="text-wrapper-15">Bí đỏ hồ lô dẻo, ngon, chất lượng, giàu dinh dưỡng</p>
          <div className="text-wrapper-16">8.000đ</div>
          <img className="star-6" src="/img/star-6-1.svg"/>
          <img className="star-7" src="/img/star-6-1.svg"/>
          <img className="star-8" src="/img/star-6-1.svg"/>
          <img className="star-9" src="/img/star-6-1.svg"/>
          <img className="star-10" src="/img/star-6-1.svg"/>
          <button className="button-wrapper">
            <button className="button">Mua ngay</button>
          </button>
        </div>
        <div className="product-3" onClick={gotoProduct}>
          <img className="image-2" src="/img/image-7-18.png"/>
          <div className="text-wrapper-14">Dưa hấu Long An</div>
          <p className="text-wrapper-15">Vị thuốc giải nhiệt ngày hè. Dưa vỏ xanh mỏng, cuống nhỏ, mọng nước, thanh
            mát</p>
          <div className="text-wrapper-16">12.000đ</div>
          <img className="star-6" src="/img/star-6-1.svg"/>
          <img className="star-7" src="/img/star-6-1.svg"/>
          <img className="star-8" src="/img/star-6-1.svg"/>
          <img className="star-9" src="/img/star-6-1.svg"/>
          <img className="star-10" src="/img/star-6-1.svg"/>
          <button className="button-wrapper">
            <button className="button">Mua ngay</button>
          </button>
        </div>
        <div className="product-4" onClick={gotoProduct}>
          <img className="image-2" src="/img/image-7-19.png"/>
          <div className="text-wrapper-14">Hành lá</div>
          <p className="text-wrapper-15">Hành lá – loại rau gia vị vô cùng bổ dưỡng</p>
          <div className="text-wrapper-16">10.000đ</div>
          <img className="star-6" src="/img/star-6-1.svg"/>
          <img className="star-7" src="/img/star-6-1.svg"/>
          <img className="star-8" src="/img/star-6-1.svg"/>
          <img className="star-9" src="/img/star-6-1.svg"/>
          <img className="star-10" src="/img/star-6-1.svg"/>
          <button className="button-wrapper">
            <button className="button">Mua ngay</button>
          </button>
        </div>
        <div className="product-5" onClick={gotoProduct}>
          <img className="image-2" src="/img/image-7-20.png"/>
          <div className="text-wrapper-14">Bắp cải trắng</div>
          <p className="text-wrapper-15">Bắp cải là loại rau bổ dưỡng vừa tốt cho sức khỏe, chứa nhiều vitamin C,
            kali</p>
          <div className="text-wrapper-16">15.000đ</div>
          <img className="star-6" src="/img/star-6-1.svg"/>
          <img className="star-7" src="/img/star-6-1.svg"/>
          <img className="star-8" src="/img/star-6-1.svg"/>
          <img className="star-9" src="/img/star-6-1.svg"/>
          <img className="star-10" src="/img/star-6-1.svg"/>
          <button className="button-wrapper">
            <button className="button">Mua ngay</button>
          </button>
        </div>
        <div className="xem-them-2">
          <div className="text-wrapper-17">Xem thêm</div>
          <img className="polygon-2" src="/img/polygon-2-1.svg"/>
        </div>
      </div>
      <div className="footer">
        <div className="USP">
          <img className="layer-4" src="/img/layer-3-8.svg"/>
          <div className="giao-h-ng-mi-n-ph">GIAO HÀNG NHANH CHÓNG, TIẾT KIỆM</div>
          <p className="ch-nh-s-ch-i-tr-linh">CHÍNH SÁCH ĐỔI TRẢ LINH HOẠT</p>
          <div className="h-tr">HỖ TRỢ 24/7</div>
          <p className="text-wrapper-20">Hỗ trợ bà con nông dân giao hàng nhanh chóng khắp cả nước.</p>
          <p className="text-wrapper-21">Giúp bà con nông dân và người tiêu dùng đổi trả thuận tiện hơn</p>
          <p className="text-wrapper-22">Đội ngũ hỗ trợ bà con 24/7 nhanh chóng, tức thời</p>
          <img className="layer-5" src="/img/icon/flex_change.png"/>
          <img className="layer-6" src="/img/icon/support.png"/>
        </div>
        <div className="footer-2">
          <div className="overlap-group-wrapper">
            <div className="overlap-group-5">
              <div className="logo-2">
                <img className="img" src="/img/grocery-cart-1-2.png"/>
                <div className="text-wrapper-23">Funong</div>
                <div className="text-wrapper-24">.vn</div>
              </div>
              <img className="img" src="/img/grocery-cart-white-1.png"/>
            </div>
          </div>
          <div className="overlap-3">
            <p className="text-wrapper-25">
              Funong.vn là cầu nối giúp bà con nông dân có thể đưa sản phẩm của mình tới tay người tiêu dùng một cách dễ
              dàng và thuận tiện. Bà con nông dân đỡ được nỗi lo về chi phí vận chuyển cũng như chi phí bán hàng mà vẫn
              đạt được hiệu quả cao nhất
            </p>
            <p className="text-wrapper-26">223 Trần Hưng Đạo, Quận 1, TPHCM</p>
          </div>
          <img className="layer-7" src="/img/icon/location.png"/>
          <img className="layer-8" src="/img/icon/phone.png"/>
          <div className="text-wrapper-27">1900 6067</div>
          <div className="flexcontainer">
            <p className="text-i">
              <span className="text-wrapper-28">HỖ TRỢ KHÁCH HÀNG</span>
              <span className="text-wrapper-29">&nbsp;&nbsp; <br/></span>
            </p>
            <p className="text-i">
              <span className="text-wrapper-29">Chính sách giao hàng&nbsp;&nbsp;&nbsp;&nbsp; <br/></span>
            </p>
            <p className="text-i">
              <span className="text-wrapper-29">Chính sách đổi trả&nbsp;&nbsp;&nbsp;&nbsp; <br/></span>
            </p>
            <p className="text-i">
              <span className="text-wrapper-29">Chính sách thành viên&nbsp;&nbsp;&nbsp;&nbsp;<br/></span>
            </p>
            <p className="text-i">
              <span className="text-wrapper-29">Hướng dẫn mua hàng và hoàn tiền&nbsp;&nbsp;&nbsp;&nbsp; <br/></span>
            </p>
            <p className="text-i">
              <span className="text-wrapper-29">Chính sách xuất hóa đơn đỏ&nbsp;&nbsp;&nbsp;&nbsp; <br/></span>
            </p>
            <p className="text-i"><span className="text-wrapper-29">Giấy chứng nhận Vệ sinh ATTP</span></p>
          </div>
          <div className="i-t-c-v-n-chuy-n">ĐỐI TÁC VẬN CHUYỂN</div>
          <div className="i-t-c-thanh-to-n">ĐỐI TÁC THANH TOÁN</div>
          <div className="group-2">
            <div className="goo-pay">GOOPAY</div>
            <img className="layer-9" src="/img/layer-3-14.svg"/>
          </div>
          <div className="flexcontainer-i">
            <p className="text-i">
              <span className="text-wrapper-29"><br/></span>
            </p>
            <p className="text-i">
              <span className="text-wrapper-29"><br/></span>
            </p>
            <p className="text-i">
              <span className="text-wrapper-29">Hướng dẫn mua hàng <br/></span>
            </p>
            <p className="text-i">
              <span className="text-wrapper-29">Hóa đơn điện tử <br/></span>
            </p>
            <p className="text-i">
              <span className="text-wrapper-29">Chính sách khách hàng <br/></span>
            </p>
            <p className="text-i">
              <span className="text-wrapper-29">Tích điểm <br/></span>
            </p>
            <p className="text-i"><span className="text-wrapper-29">Cảnh báo giả mạo</span></p>
          </div>
          <div className="image-wrapper"><img className="image-3" src="/img/image-13.png"/></div>
          <img className="image-4" src="/img/image-12.png"/>
        </div>
      </div>
    </div>
  );
};
