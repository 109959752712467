/*
We're constantly improving the code you see. 
Please share your feedback here: https://form.asana.com/?k=uvp-HPgd3_hyoXRBw1IcNg&d=1152665201300829
*/

import PropTypes from "prop-types";
import React from "react";
import { Link } from "react-router-dom";
import "./style.css";

export const Logo = ({ className, divClassName, divClassNameOverride, to }) => {
  return (
    <Link className={`logo ${className}`} to={to}>
      <img className="grocery-cart" alt="Grocery cart" src="/img/grocery-cart-1-2.png" />
      <div className={`text-wrapper-9 ${divClassName}`}>Funong</div>
      <div className={`text-wrapper-10 ${divClassNameOverride}`}>.vn</div>
    </Link>
  );
};

Logo.propTypes = {
  to: PropTypes.string,
};
