/*
We're constantly improving the code you see. 
Please share your feedback here: https://form.asana.com/?k=uvp-HPgd3_hyoXRBw1IcNg&d=1152665201300829
*/

import React from "react";

export const Property1Default1 = ({ className }) => {
  return (
    <svg
      className={`property-1-default-1 ${className}`}
      fill="none"
      height="21"
      viewBox="0 0 26 21"
      width="26"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        className="path"
        d="M21.8007 15.0525H8.36067C7.91067 15.0525 7.51067 14.7525 7.40067 14.3225L4.84067 5.04247C4.76067 4.74247 4.82068 4.42247 5.01067 4.17247C5.20068 3.92247 5.49067 3.78247 5.81067 3.78247H24.3707C24.6807 3.78247 24.9807 3.93247 25.1607 4.17247C25.3507 4.42247 25.4107 4.74247 25.3307 5.04247L22.7707 14.3225C22.6507 14.7525 22.2607 15.0525 21.8107 15.0525H21.8007ZM9.13067 13.0525H21.0507L23.0607 5.77247H7.12067L9.13067 13.0525Z"
        fill="#9E9E9E"
      />
      <path
        className="path"
        d="M8.36067 15.0525C7.92067 15.0525 7.52067 14.7625 7.40067 14.3125L4.06067 2.14248L0.95067 2.00248C0.41067 1.98248 -0.0193296 1.51248 0.000670446 0.962476C0.0206704 0.412476 0.46067 -0.0375238 1.04067 0.00247617L4.87067 0.172476C5.30067 0.192476 5.68067 0.492476 5.79067 0.902476L9.32067 13.7825C9.46067 14.3125 9.15067 14.8625 8.62067 15.0125C8.53067 15.0325 8.44067 15.0525 8.35067 15.0525H8.36067Z"
        fill="#9E9E9E"
      />
      <path
        className="path"
        d="M10.7505 21.0024C9.23049 21.0024 8.00049 19.7724 8.00049 18.2524C8.00049 16.7324 9.23049 15.5024 10.7505 15.5024C12.2705 15.5024 13.5005 16.7324 13.5005 18.2524C13.5005 19.7724 12.2705 21.0024 10.7505 21.0024ZM10.7505 17.5024C10.3405 17.5024 10.0005 17.8424 10.0005 18.2524C10.0005 18.6624 10.3405 19.0024 10.7505 19.0024C11.1605 19.0024 11.5005 18.6624 11.5005 18.2524C11.5005 17.8424 11.1605 17.5024 10.7505 17.5024Z"
        fill="#9E9E9E"
      />
      <path
        className="path"
        d="M19.0806 21.0024C17.5606 21.0024 16.3306 19.7724 16.3306 18.2524C16.3306 16.7324 17.5606 15.5024 19.0806 15.5024C20.6006 15.5024 21.8306 16.7324 21.8306 18.2524C21.8306 19.7724 20.6006 21.0024 19.0806 21.0024ZM19.0806 17.5024C18.6706 17.5024 18.3306 17.8424 18.3306 18.2524C18.3306 18.6624 18.6706 19.0024 19.0806 19.0024C19.4906 19.0024 19.8306 18.6624 19.8306 18.2524C19.8306 17.8424 19.4906 17.5024 19.0806 17.5024Z"
        fill="#9E9E9E"
      />
    </svg>
  );
};
