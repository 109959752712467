/*
We're constantly improving the code you see. 
Please share your feedback here: https://form.asana.com/?k=uvp-HPgd3_hyoXRBw1IcNg&d=1152665201300829
*/

import React from "react";
import {Btn} from "../Btn";
import {Property1Default1} from "../../icons/Property1Default1";
import {IconLove} from "../../icons/IconLove";
import {IconUser} from "../../icons/IconUser";
import {Logo} from "../Logo";
import {MainNav} from "../MainNav";
import "./style.css";

export const Header = () => {
  return (
    <div className="site-heading">
      <div className="heading">
        <div className="btn-wrapper">
          <Btn className="btn-3" text="Tìm kiếm"/>
        </div>
        <Property1Default1 className="icon-gio-hang"/>
        <IconLove className="icon-love"/>
        <IconUser className="icon-user"/>
        <Logo className="logo-instance" to="/"/>
      </div>
      <div className="topheading">
        <div className="text-wrapper-23 marquee">
          <span>Phiên bản thử nghiệm</span>
        </div>
        <p className="hotline">
          <span className="text-wrapper-24">Hotline: </span>
          <span className="text-wrapper-25">1900 6067</span>
        </p>
        <img className="icon-phone-4" alt="Icon phone" src="/img/icon-phone-7.svg"/>
      </div>
      <MainNav/>
    </div>
  );
};

Logo.propTypes = {};
