import React, {useEffect} from "react";
import "./style.css";
import {Link, useParams} from "react-router-dom";
import {Home} from "../../components/Provider/Home";
import {ManageProduct} from "../../components/Provider/ManageProduct";
import {ManageOrder} from "../../components/Provider/ManageOrder";
import {CreateProduct} from "../../components/Provider/CreateProduct";
import {CreateOrder} from "../../components/Provider/CreateOrder";
import {CreateProductSuccess} from "../../components/Provider/CreateProductSuccess";
import "../../styleguide.css";
import {PROVIDER} from "../../utils/constants";
import {Heading} from "../../components/Heading";


const MenuItem = ({name, iconPath, slug, active}) => {
  return (
    <Link to={`/nha-cung-cap/${slug || ''}`}>
      <div className={`menu-item-flex${active ?" active":""}`}>
        <div className="frame-left">
          <img className="menu-icon" src={iconPath}/>
          <p className="menu-text">{name}</p>
        </div>
        <img className='menu-vector' src="/img/provider/vector-6.svg"/>
      </div>
    </Link>
  );
}

export const Provider = () => {
  const params = useParams();
  const {slug} = params || {};
  
  useEffect(() => {
    window.scrollTo({ top: 0, behavior: "smooth" });
    setBodyOverflowHidden(true);
    return () => {
      setBodyOverflowHidden(false);
    }
  }, [slug])
  
  const setBodyOverflowHidden = (hidden) => {
    document.body.style.overflowY = hidden ? 'hidden' : 'auto';
  }
  
  
  
  const renderComponent = () => {
    switch (slug) {
      case PROVIDER.MANAGE_PRODUCT.slug:
        return <ManageProduct/>;
      case PROVIDER.MANAGE_ORDER.slug:
        return <ManageOrder />;
      case PROVIDER.CREATE_PRODUCT.slug:
        return <CreateProduct />;
      case PROVIDER.CREATE_ORDER.slug:
        return <CreateOrder />;
      case PROVIDER.CREATE_PRODUCT_SUCCESS.slug:
        return <CreateProductSuccess />;
      default:
        return <Home />;
    }
  }
  
  return (
    <div className="element-dashboard">
      {renderComponent()}
      <div className="left">
        <div className="overlap-group-wrapper">
          <Link to={'/'}>
            <div className="overlap-group-2">
              <div className="logo">
                <img className="img-2" src="/img/provider/grocery-cart-1-1.png"/>
                <div className="text-wrapper-23">Funong</div>
                <div className="text-wrapper-24">.vn</div>
              </div>
              <img className="img-2" src="/img/provider/grocery-cart-white-1.png"/>
            </div>
          </Link>
        </div>
        <div className="btn-dang-xuat">
          <div className="text-wrapper-25">Đăng xuất</div>
          <img className="layer-7" src="/img/provider/layer-3-22.svg"/>
        </div>
        <div className="overlap-2">
          <div className="overlap-3">
            <div className="main">
              <p className="text-wrapper-26">Cửa hàng Gạo chú Tám Cần Thơ</p>
              <img className="layer-8" src="/img/provider/layer-3-23.svg"/>
              <img className="vector" src="/img/provider/vector-7.svg"/>
              <img className="layer-9" src="/img/provider/layer-3-24.svg"/>
              <div className="overlap-4">
                <div className="overlap-5">
                  <p className="text-wrapper-27">12 Ấp 3, Xã Cái Răng, Cần Thơ</p>
                  <img className="grocery-cart-white" src="/img/provider/grocery-cart-white-1-1.png"/>
                </div>
                <div className="text-wrapper-28">150 lượt theo dõi</div>
              </div>
              <div className="text-wrapper-29">0903 123 456</div>
            </div>
            <img className="avatar" src="/img/provider/avatar-1.png"/>
          </div>
          <div className="overlap-wrapper">
            <div className="overlap-6">
              <div className="text-wrapper-30">Chia sẻ</div>
              <img className="layer-10" src="/img/provider/layer-3-25.svg"/>
            </div>
          </div>
        </div>
        <div className="text-wrapper-31">Đối tác vận chuyển</div>
        <div className="text-wrapper-32">Đối tác thanh toán</div>
        <div className="div-wrapper">
          <div className="overlap-7">
            <div className="goo-pay">GOOPAY</div>
            <img className="layer-11" src="/img/provider/layer-3-26.svg"/>
          </div>
        </div>
        <div className="image-wrapper"><img className="image-4" src="/img/provider/image-13-1.png"/></div>
      </div>
      <Heading/>
      <div className="left-menu">
        
        <MenuItem
          name="Thông tin nhà bán hàng"
          iconPath="/img/provider/layer-3-29.svg"
        />
        <MenuItem
          active={slug === PROVIDER.MANAGE_ORDER.slug}
          name="Quản lý đơn hàng"
          iconPath="/img/provider/layer-3-31.svg"
          slug={PROVIDER.MANAGE_ORDER.slug}
        />
        <MenuItem
          active={
          slug === PROVIDER.MANAGE_PRODUCT.slug ||
          slug === PROVIDER.CREATE_PRODUCT.slug
        }
          name="Quản lý sản phẩm"
          iconPath="/img/provider/layer-3-34.svg"
          slug={PROVIDER.MANAGE_PRODUCT.slug}
        />
        <MenuItem
          name="Quản lý cửa hàng"
          iconPath="/img/provider/layer-3-33.svg"
          />
        <MenuItem
          name="Cài đặt tài khoản"
          iconPath="/img/provider/layer-3-30.svg"
          />
      </div>
    </div>
  );
};
