import PropTypes from "prop-types";
import React from "react";
import "./index.css";
import {Link, useParams} from "react-router-dom";
import {PROVIDER} from "../../utils/constants";


const Breadcrumb = ({slug})=> {
  
  if(slug === PROVIDER.MANAGE_PRODUCT.slug){
    return (
      <div className='heading-crumb'>
        <img className="" src="/img/provider/heading/vector-1.svg"/>
        <div className="">Quản lý sản phẩm</div>
      </div>
    )
  } else if (slug === PROVIDER.CREATE_PRODUCT.slug){
    return (
      <div className='heading-crumb'>
        <img className="" src="/img/provider/heading/vector-1.svg"/>
        <div className="">Quản lý sản phẩm</div>
        <img className="" src="/img/provider/heading/vector-1.svg"/>
        <div className="">Tạo sản phẩm</div>
      </div>
    )
  } else if (slug === PROVIDER.MANAGE_ORDER.slug){
    return (
      <div className='heading-crumb'>
        <img className="" src="/img/provider/heading/vector-1.svg"/>
        <div className="">Tạo sản phẩm</div>
      </div>
    )
  }
  
}

export const Heading = () => {
  
  const params = useParams();
  const {slug} = params || {};
  return (
    <div className="heading">
      <div className="group-3">
        <div className="overlap-group-3">
          <img className="layer-12" src="/img/provider/layer-3-27.svg"/>
          <div className="thong-bao">
            <div className="text-wrapper-33">5</div>
          </div>
        </div>
      </div>
      <Link to={`/nha-cung-cap/${PROVIDER.CREATE_ORDER.slug}`}>
        <div className="contact-futaex">
          <div className="img-wrapper"><img className="image-5" src="/img/provider/image-13.png"/></div>
          <div className="text-wrapper-34">Liên hệ với</div>
          <img className="polygon-4" src="/img/provider/polygon-4.svg"/>
        </div>
      </Link>
      <div className="group-4">
        <img className="layer-13" src="/img/provider/layer-3-28.svg"/>
        <div className="text-wrapper-35">Trang chủ</div>
      </div>
      
      <Breadcrumb slug={slug} />
    </div>
  );
};

Heading.propTypes = {
  layer: PropTypes.string,
  image: PropTypes.string,
  polygon: PropTypes.string,
  img: PropTypes.string,
};
