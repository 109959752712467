import React, {useEffect} from "react";
import "./style.css";
import {Link} from "react-router-dom";
import {PROVIDER} from "../../../utils/constants";


export const CreateProductSuccess = () => {
  
  return (
    <div className="form-create-sp-ss animate-fadeIn">
      <div className="group-ss">
        <p className="text-wrapper">Tạo sản phẩm mới thành công</p>
        <Link to={`/nha-cung-cap/${PROVIDER.MANAGE_PRODUCT.slug}`}>
          <div className="li-n-h-wrapper">
            <div className="li-n-h">Về trang sản phẩm</div>
          </div>
        </Link>
        <img className="frame" src="/img/provider/create_product_success/frame.svg"/>
        <img className="layer" src="/img/provider/create_product_success/layer-3.svg"/>
      </div>
    </div>
  );
};
