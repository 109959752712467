import React, {useEffect} from "react";
import "./style.css";
import {Link} from "react-router-dom";
import {PROVIDER} from "../../../utils/constants";


export const CreateProduct = () => {
  
  return (
    <div className="form-create-sp animate-fadeIn">
      <div className="text-wrapper">Thông tin chung</div>
      <div className="overlap">
        <div className="div-cp">Hình thức bán</div>
        <img className="layer" src="/img/provider/create_product/layer-3.svg"/>
        <div className="overlap-group">
          <div className="group-cp">
            <img className="img" src="/img/provider/create_product/layer-3-1.svg"/>
            <p className="p">Gạo Tám Xoan Cần Thơ</p>
          </div>
          <div className="group-2">
            <img className="img" src="/img/provider/create_product/layer-3-2.svg"/>
            <div className="text-wrapper-2 cp1">Nông sản</div>
          </div>
          <div className="hinh-thuc-ban">
            <div className="group-3">
              <div className="overlap-group-2">
                <div className="overlap-group-2">
                  <div className="rectangle-wrapper">
                    <div className="rectangle"></div>
                  </div>
                  <div className="div-wrapper">
                    <div className="rectangle"></div>
                  </div>
                  <div className="text-wrapper-3-cp">Khoảng giá</div>
                  <p className="text-wrapper-4">Số lượng đặt hàng ít nhất</p>
                </div>
                <div className="t">20.000</div>
                <img className="vector" src="/img/provider/create_product/vector-2.svg"/>
                <div className="element">10</div>
              </div>
              <div className="overlap-2">
                <div className="overlap-3">
                  <div className="group-4">
                    <div className="rectangle"></div>
                  </div>
                  <div className="group-5">
                    <div className="rectangle"></div>
                  </div>
                  <div className="n">20.000.000</div>
                </div>
                <div className="text-wrapper-5-cp">Đơn vị</div>
                <img className="vector-2" src="/img/provider/create_product/vector-2.svg"/>
                <img className="vector-3" src="/img/provider/create_product/vector-2.svg"/>
              </div>
              <div className="group-6">
                <div className="rectangle-2"></div>
              </div>
              <div className="text-wrapper-6">Mô tả chi tiết</div>
            </div>
            <div className="group-7">
              <div className="ban-le">
                <div className="text-wrapper-7">Bán lẻ</div>
                <div className="ellipse"></div>
              </div>
              <div className="ban-si-va-le">
                <div className="ellipse"></div>
                <div className="text-wrapper-7">Bán sỉ &amp; lẻ</div>
              </div>
              <div className="overlap-4">
                <div className="ban-si">
                  <p className="text-wrapper-8">Bán sỉ liên hệ sau</p>
                  <div className="ellipse-wrapper">
                    <div className="ellipse-2"></div>
                  </div>
                </div>
                <div className="rectangle-3"></div>
              </div>
              <img className="line" src="/img/provider/create_product/line-9.svg"/>
            </div>
            <div className="dang-hinh">
              <div className="overlap-5">
                <div className="rectangle-4"></div>
                <img className="polygon" src="/img/provider/create_product/polygon-6.svg"/>
                <img className="polygon-2" src="/img/provider/create_product/polygon-7.svg"/>
                <div className="ellipse-3"></div>
                <div className="rectangle-5"></div>
                <div className="text-wrapper-9">+ Đăng hình ảnh</div>
              </div>
            </div>
            <div className="dang-hinh-2">
              <div className="polygon-wrapper"><img className="polygon-3" src="/img/provider/create_product/polygon-7-1.svg"/></div>
              <div className="overlap-6">
                <div className="text-wrapper-10">+ Đăng video</div>
              </div>
            </div>
            <div className="li-n-h-wrapper">
              <div className="li-n-h">Chỉnh sửa</div>
            </div>
            <Link to={`/nha-cung-cap/${PROVIDER.CREATE_PRODUCT_SUCCESS.slug}`}>
            <div className="group-8">
              <div className="li-n-h">Đăng sản phẩm</div>
            </div>
            </Link>
          </div>
          <img className="line-2" src="/img/provider/create_product/line-11.svg"/>
          <img className="line-3" src="/img/provider/create_product/line-11.svg"/>
          <div className="text-wrapper-11">kg</div>
        </div>
      </div>
      <img className="group-9" src="/img/provider/create_product/group-14.png"/>
      <p className="t-n-s-n-ph-m"><span className="span">Tên sản phẩm </span> <span className="text-wrapper-12-cp">*</span>
      </p>
      <p className="danh-m-c-s-n-ph-m">
        <span className="span">Danh mục sản phẩm</span> <span className="text-wrapper-12-cp"> *</span>
      </p>
    </div>
  );
};
