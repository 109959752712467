
import PropTypes from "prop-types";
import React from "react";
import "./style.css";
import {Home} from "../Home";
import {Sanpham} from "../Sanpham";
import {LienHe} from "../LienHe";
import {Link, useNavigate} from "react-router-dom";

export const MainNav = ({}) => {
  return (
    <div className="main-nav">
      <img className="icon-gift" alt="Icon gift" src="/img/icon-gift-1.svg"/>
      <div className="text-wrapper-26">Khuyến mãi HOT</div>
      <Home className="home-instance" property1="default"/>
      <Sanpham className="sanpham-instance" property1="default"/>
      <Link to={'/nha-cung-cap'}>
        <div className="nha-cung-cap">
          <div className="text-wrapper-27">Nhà cung cấp</div>
          <div className="icon-NCC">
            <div className="overlap-group-11">
              <img className="vector-10" alt="Vector" src="/img/vector-16.svg"/>
              <img className="vector-11" alt="Vector" src="/img/vector-13.svg"/>
              <img className="vector-12" alt="Vector" src="/img/vector-34.svg"/>
              <img className="vector-13" alt="Vector" src="/img/vector-35.svg"/>
            </div>
          </div>
        </div>
      </Link>
      <LienHe className="lien-he-instance" property1="default"/>
      <Link to={'/lien-he-futaex'}>
        <div className="contact-futaex">
          <div className="image-wrapper">
            <img className="image-9" alt="Image" src="/img/image-13-1.png"/>
          </div>
          <div className="text-wrapper-28">Liên hệ với</div>
          <img className="polygon-2" alt="Polygon" src="/img/polygon-4.svg"/>
        </div>
      </Link>
    </div>
  );
};

MainNav.propTypes = {};
