import React, {useEffect} from "react";
import "./style.css";
import {Link} from "react-router-dom";
import {PROVIDER} from "../../../utils/constants";


export const CreateOrder = () => {
  
  return (
    <div className="form-contact-co animate-fadeIn">
      <div className="overlap">
        <div className="text-wrapper">Tạo đơn hàng mới</div>
        <div className="overlap-group">
          <div className="div-co">Thông tin người gửi</div>
          <div className="text-wrapper-2">Thông tin người nhận</div>
          <div className="text-wrapper-3">Thông tin kiện hàng</div>
          <div className="group-co">
            <div className="rectangle"></div>
            <img className="vector" src="/img/provider/create_order/vector-4.svg"/>
          </div>
          <div className="rectangle-wrapper">
            <div className="rectangle-2"></div>
          </div>
          <div className="div-wrapper">
            <div className="rectangle-2"></div>
          </div>
          <div className="group-2">
            <div className="rectangle-2"></div>
          </div>
          <div className="group-3">
            <div className="rectangle-3"></div>
          </div>
          <div className="group-4">
            <div className="rectangle-4"></div>
          </div>
          <div className="group-5">
            <div className="rectangle-4"></div>
          </div>
          <div className="group-6">
            <div className="rectangle-4"></div>
          </div>
          <div className="group-7">
            <div className="rectangle-2"></div>
          </div>
          <div className="group-8">
            <div className="rectangle-2"></div>
          </div>
          <div className="group-9">
            <div className="rectangle-5"></div>
          </div>
          <div className="group-10">
            <div className="rectangle-6"></div>
          </div>
          <div className="group-11">
            <div className="rectangle-7"></div>
          </div>
          <div className="group-12">
            <div className="rectangle-8"></div>
            <img className="img" src="/img/provider/create_order/vector-1.svg"/>
          </div>
          <div className="text-wrapper-4">Tỉnh/ Thành phố</div>
          <div className="text-wrapper-5">Quận/ Huyện</div>
          <div className="text-wrapper-6">Phường/ Xã</div>
          <p className="kh-i-l-ng-kg"><span className="span-co">Khối lượng (kg) </span> <span
            className="text-wrapper-7-co">*</span></p>
          <div className="text-wrapper-8">Kích thước (cm)</div>
          <p className="p">Vui lòng nhập họ và tên người nhận</p>
          <p className="text-wrapper-9">Nhập địa chỉ người nhận</p>
          <div className="text-wrapper-10">Tỉnh/ Thành...</div>
          <div className="text-wrapper-11-co">Quận/ Huyện...</div>
          <div className="text-wrapper-12-co">Phường/ Xã...</div>
          <img className="vector-2" src="/img/provider/create_order/vector-4.svg"/>
          <p className="text-wrapper-13-co">Vui lòng nhập số điện thoại người nhận</p>
          <div className="text-wrapper-14">Dài</div>
          <div className="text-wrapper-15">Rộng</div>
          <p className="text-wrapper-16">Futa Express sẽ lấy hàng tận nơi theo địa chỉ người gửi</p>
          <img className="line" src="/img/provider/create_order/line-7.svg"/>
          <div className="frame">
            <div className="text-wrapper-17">Tạm tính</div>
            <p className="kh-i-l-ng-ki-n-h-ng">
              <span className="text-wrapper-18-co">Khối lượng kiện hàng 1<br/></span>
              <span className="text-wrapper-19-co">0 kg</span>
            </p>
            <p className="c-c-ch-nh">
              <span className="text-wrapper-18-co">Cước chính<br/></span> <span className="text-wrapper-19-co">0 đ</span>
            </p>
            <p className="t-ng-ki-n">
              <span className="text-wrapper-18-co">Tổng kiện 1<br/></span> <span className="text-wrapper-20">0 đ</span>
            </p>
          </div>
          <img className="line-2" src="/img/provider/create_order/line-8.svg"/>
          <p className="t-ng-i-h-ng-ho-v">
            <span className="span-co">Tổng đài hàng hoá và CSKH:&nbsp;&nbsp; </span>
            <span className="text-wrapper-21-co">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </span>
            <span className="text-wrapper-22-co">1900 6767</span>
          </p>
          <img className="image" src="/img/provider/create_order/image-20.png"/>
          <div className="rectangle-9"></div>
        </div>
        <img className="layer" src="/img/provider/create_order/layer-3.svg"/>
        <img className="group-13" src="/img/provider/create_order/group-14.png"/>
        <img className="group-14" src="/img/provider/create_order/group-17.png"/>
        <div className="overlap-2">
          <div className="group-15">
            <div className="rectangle-10"></div>
          </div>
          <div className="text-wrapper-23">Cao</div>
        </div>
        <div className="text-wrapper-24">Thông tin người gửi</div>
        <p className="h-v-t-n"><span className="span-co">Họ và Tên </span> <span className="text-wrapper-7-co">*</span></p>
        <p className="h-nh-ki-n-h-ng"><span className="span-co">Hình kiện hàng </span> <span
          className="text-wrapper-7-co">*</span></p>
        <div className="text-wrapper-25">Tối đa 5 hình</div>
        <div className="text-wrapper-26">Loại sản phẩm</div>
        <div className="text-wrapper-27">Giá trị hàng (VND)</div>
        <div className="text-wrapper-28">Địa chỉ</div>
        <p className="i-n-tho-i"><span className="span-co">Điện thoại </span> <span className="text-wrapper-7-co">*</span></p>
        <p className="t-n-h-ng-ho"><span className="span-co">Tên hàng hoá </span> <span className="text-wrapper-7-co">*</span>
        </p>
        <div className="text-wrapper-29">Hình thức giao nhận</div>
        <p className="text-wrapper-30">Nhận tận nơi, giao tận nơi</p>
        <div className="text-wrapper-31">Chọn loại sản phâm</div>
        <div className="text-wrapper-32">0</div>
        <img className="vector-3" src="/img/provider/create_order/vector-4.svg"/>
        <img className="vector-4" src="/img/provider/create_order/vector-4.svg"/>
        <p className="text-wrapper-33">Nhập mô tả hàng hoá</p>
        <div className="text-wrapper-34">0</div>
        <div className="frame-2">
          <div className="text-wrapper-35">Nhập địa chỉ</div>
        </div>
        <div className="overlap-3">
          <div className="text-wrapper-36">+</div>
        </div>
      </div>
      <Link to={`/nha-cung-cap/${PROVIDER.CREATE_PRODUCT_SUCCESS.slug}`}>
        <div className="li-n-h-wrapper">
          <div className="li-n-h">Tạo đơn</div>
        </div>
      </Link>
    </div>
  );
};
