

export const PROVIDER = {
	MANAGE_PRODUCT: {
		slug: "quan-ly-san-pham",
	},
	MANAGE_ORDER: {
		slug: "quan-ly-don-hang",
	},
	CREATE_ORDER: {
		slug: "tao-don-hang",
	},
	CREATE_PRODUCT: {
		slug: "tao-san-pham",
	},
	CREATE_PRODUCT_SUCCESS: {
		slug: "tao-san-pham-thanh-cong",
	},
}