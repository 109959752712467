/*
We're constantly improving the code you see. 
Please share your feedback here: https://form.asana.com/?k=uvp-HPgd3_hyoXRBw1IcNg&d=1152665201300829
*/

import PropTypes from "prop-types";
import React from "react";
import { useReducer } from "react";
import "./style.css";
import {Link, useNavigate} from "react-router-dom";

export const Home = ({ property1, className, iconHome = "/img/icon-home-2.svg" }) => {
  
  const [state, dispatch] = useReducer(reducer, {
    property1: property1 || "default",
  });
  
  return (
    <Link to={'/'}>
      <div
        className={`home property-1-6-${state.property1} ${className}`}
        onMouseLeave={() => {
          dispatch("mouse_leave");
        }}
        onMouseEnter={() => {
          dispatch("mouse_enter");
        }}
      >
        {state.property1 === "default" && (
          <>
            <img className="icon-home" alt="Icon home" src={iconHome}/>
            <div className="text-wrapper-11">Trang chủ</div>
          </>
        )}
        
        {state.property1 === "variant-2" && (
          <div className="overlap-group-6">
            <div className="rectangle-3"/>
            <img className="icon-home" alt="Icon home" src="/img/icon-home-2.svg"/>
            <div className="text-wrapper-11">Trang chủ</div>
            <div className="rectangle-4"/>
          </div>
        )}
      </div>
    </Link>
  );
};

function reducer(state, action) {
  switch (action) {
    case "mouse_enter":
      return {
        ...state,
        property1: "variant-2",
      };
    
    case "mouse_leave":
      return {
        ...state,
        property1: "default",
      };
  }
  
  return state;
}

Home.propTypes = {
  property1: PropTypes.oneOf(["variant-2", "default"]),
  iconHome: PropTypes.string,
};
