/*
We're constantly improving the code you see. 
Please share your feedback here: https://form.asana.com/?k=uvp-HPgd3_hyoXRBw1IcNg&d=1152665201300829
*/

import React from "react";

export const IconLove = ({ className }) => {
  return (
    <svg
      className={`icon-love-1 ${className}`}
      fill="none"
      height="22"
      viewBox="0 0 25 22"
      width="25"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g className="g" clipPath="url(#clip0_27_129)">
        <path
          className="path"
          d="M12.4898 21.35C12.3198 21.35 12.1598 21.31 12.0098 21.23C11.9698 21.21 11.9198 21.18 11.8798 21.15L11.5698 20.94C10.7898 20.4 8.96983 19.13 7.75983 18.2C3.14983 14.6 0.799828 12.01 0.349828 10.05C0.139828 9.4 0.00982832 8.62 -0.000171677 7.82C-0.0301717 5.7 0.779828 3.64 2.19983 2.19C3.49983 0.87 5.20983 0.13 7.00983 0.13C9.04983 0.13 10.9198 0.97 12.2498 2.42C13.5598 0.91 15.4098 0.03 17.4298 0H17.5598C21.3998 0 24.5698 3.37 24.6198 7.51C24.6198 8.31 24.5198 9.09 24.2998 9.83C23.9298 11.71 21.5798 14.44 17.1398 18.07C15.4198 19.49 14.0298 20.53 13.0098 21.18C12.8498 21.28 12.6598 21.34 12.4698 21.34L12.4898 21.35ZM7.07983 2.13C5.74983 2.13 4.54983 2.66 3.62983 3.59C2.56983 4.67 1.97983 6.2 1.99983 7.8C1.99983 8.4 2.09983 8.98 2.27983 9.52C2.48983 10.42 3.59983 12.41 8.98983 16.6C10.0598 17.43 11.6598 18.55 12.5098 19.14C13.3998 18.53 14.5298 17.66 15.8898 16.54C21.1498 12.23 22.2098 10.19 22.3798 9.36C22.5598 8.72 22.6398 8.14 22.6398 7.54C22.5998 4.49 20.3298 2 17.5798 2H17.4698C15.6798 2.02 14.0598 3 13.1498 4.6C12.9698 4.91 12.6498 5.1 12.2898 5.1H12.2798C11.9298 5.1 11.6098 4.92 11.4198 4.62C10.4698 3.06 8.85983 2.12 7.08983 2.12L7.07983 2.13Z"
          fill="#9E9E9E"
        />
      </g>
      <defs className="defs">
        <clipPath className="clip-path" id="clip0_27_129">
          <rect className="rect" fill="white" height="21.35" width="24.63" />
        </clipPath>
      </defs>
    </svg>
  );
};
