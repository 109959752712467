/*
We're constantly improving the code you see. 
Please share your feedback here: https://form.asana.com/?k=uvp-HPgd3_hyoXRBw1IcNg&d=1152665201300829
*/

import PropTypes from "prop-types";
import React from "react";
import { useReducer } from "react";
import {Link, useNavigate} from "react-router-dom";
import { Btn } from "../Btn";
import "./style.css";

export const Fs = ({ property1, className, data }) => {
  const navigate = useNavigate();
  const [state, dispatch] = useReducer(reducer, {
    property1: property1 || "default",
  });
  
  const {id, price, name, desc, imgPath } = data || {};
  
  return (
    <Link to={`/san-pham?id=${id}`}>
      <div
        className={`fs ${state.property1} ${className}`}
        onMouseLeave={() => {
          dispatch("mouse_leave");
        }}
        onMouseEnter={() => {
          dispatch("mouse_enter");
        }}
        onClick={() => {
          navigate("/san-pham");
        }}
      >
        <div className="overlap-group-4">
          <img
            className="image-2"
            alt="Image"
            src={state.property1 === "variant-2" ? imgPath : imgPath}
          />
          <div className="text-wrapper-5">{price}</div>
        </div>
        <div className="text-wrapper-6">{name}</div>
        <p className="d-a-ch-a-r-t-nhi-u">{desc}</p>
        <img className="star-6" alt="Star" src="/img/star-6-7.svg"/>
        <img className="star-7" alt="Star" src="/img/star-6-7.svg"/>
        <img className="star-8" alt="Star" src="/img/star-6-7.svg"/>
        <img className="star-9" alt="Star" src="/img/star-6-7.svg"/>
        <img className="star-10" alt="Star" src="/img/star-6-7.svg"/>
        <Btn className="instance-node" text="Mua ngay"/>
      </div>
    </Link>
  );
};

function reducer(state, action) {
  switch (action) {
    case "mouse_enter":
      return {
        ...state,
        property1: "variant-2",
      };
    
    case "mouse_leave":
      return {
        ...state,
        property1: "default",
      };
  }
  
  return state;
}

Fs.propTypes = {
  property1: PropTypes.oneOf(["variant-2", "default"]),
};
