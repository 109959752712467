import React from "react";
import {createBrowserRouter, RouterProvider} from "react-router-dom";
import {HomeScreen} from "./screens/HomeScreen";
import {Detail} from "./screens/Detail";
import {LienHeFutaex} from "./screens/LienHeFutaex";
import {Shop} from "./screens/Shop";
import {Checkout} from "./screens/Checkout";
import {Success} from "./screens/Success";
import {Provider} from "./screens/Provider";

const router = createBrowserRouter([
  {
    path: "/*",
    element: <HomeScreen />,
  }, {
    path: "/san-pham",
    element: <Detail />,
  }, {
    path: "/cua-hang",
    element: <Shop />,
  }, {
    path: "/thanh-toan",
    element: <Checkout />,
  }, {
    path: "/lien-he-futaex",
    element: <LienHeFutaex />,
  }, {
    path: "/success",
    element: <Success />,
  }, {
    path: "/nha-cung-cap",
    element: <Provider />,
  },
  {
    path: "/nha-cung-cap/:slug",
    element: <Provider />,
  },
]);

export const App = () => {
  return <RouterProvider router={router} />;
};
