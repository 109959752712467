import React, {useEffect} from "react";
import "./style.css";
import {Link, useLocation, useNavigate} from "react-router-dom";
import {ChoosePrice} from "./ChoosePrice";
import {Popup} from "../../components/Popup/Popup";
import {DEFAULT_PRODUCT, ProductDetail, ProductId} from "../../dummy";
import {Property1Default1} from "../../icons/Property1Default1";

export const Detail = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  
  // Example: Get the value of a query parameter named 'id'
  const paramId = queryParams.get('id') || "rice";
  const [openPopup, setOpenPopup] = React.useState(false);
  const [videoStatus, setVideoStatus] = React.useState("init");
  useEffect(() => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  }, []);
  
  const product = ProductDetail[paramId];
  
  const {id, name, imgPath,detailDesc, desc, longDesc, imgType} = product || {};
  
  const handleOnClick = () => {
    navigate("/lien-he-futaex");
  }
  
  const handleGotoCheckout = () => {
    navigate("/thanh-toan");
  }
  
  const onOpenPopup = () => {
    setOpenPopup(true);
  }
  const closePopup = () => {
    setOpenPopup(false);
  }
  const startVideo = () => {
    const video = document.getElementById("video");
    if(video) {
      video.play()
      setVideoStatus("play");
    }
  }
  
  const onEnded = () => {
    setVideoStatus("ended");
  }
  
  console.log(videoStatus)
  
  return (
    <div className="PDP animate-fadeIn">
      {openPopup &&
        <div className="popup-detail">
          <Popup closePopup={closePopup}/>
        </div>
      }
      <div className="site-heading">
        <div className="heading">
          <div className="overlap-group">
            <button className="btn">
              <button className="button">Tìm kiếm</button>
            </button>
          </div>
          <Property1Default1 className="icon-gio-hang"/>
          <img className="icon-love" src="/img/icon-love-1.svg"/>
          <img className="icon-user" src="/img/icon-user-1.svg"/>
          <div className="logo">
            <img className="img" src="/img/grocery-cart-1-2.png"/>
            <div className="text-wrapper">Funong</div>
            <div className="div">.vn</div>
          </div>
        </div>
        <div className="topheading">
          <div className="p marquee">
            <span>Phiên bản thử nghiệm</span>
          </div>
          <p className="hotline"><span className="span">Hotline: </span> <span
            className="text-wrapper-2">1900 6067</span></p>
          <img className="icon-phone" src="/img/icon-phone-1.svg"/>
        </div>
        <div className="main-nav">
          <img className="icon-gift" src="/img/icon-gift-1.svg"/>
          <div className="text-wrapper-3">Khuyến mãi HOT</div>
          <Link to={"/"}>
            <div className="home">
              <img className="icon-home" src="/img/icon-home-2.svg"/>
              <div className="text-wrapper-4">Trang chủ</div>
            </div>
          </Link>
          <Link to={"/cua-hang"}>
          <div className="sanpham">
            <div className="text-wrapper-4">Sản phẩm</div>
            <img className="icon-sanpham" src="/img/icon-sanpham-2.svg"/>
          </div>
          </Link>
          <Link to={"/nha-cung-cap"}>
            <div className="nha-cung-cap">
              <div className="text-wrapper-4">Nhà cung cấp</div>
              <div className="icon-NCC">
                <div className="overlap-group-3">
                  <img src={"/img/icon/iconNCC.png"}/>
                  <img className="vector-6" src="/img/vector-16.svg"/>
                  <img className="vector-7" src="/img/vector-13.svg"/>
                  <img className="vector-8" src="/img/vector-31.svg"/>
                  <img className="vector-9" src="/img/vector-32.svg"/>
                </div>
              </div>
            </div>
          </Link>
          <div className="lien-he">
            <div className="text-wrapper-4">Liên hệ</div>
            <img className="vector-10" src="/img/vector-11.svg"/>
          </div>
          <div className="contact-futaex" onClick={handleOnClick}>
            <div className="overlap"><img className="image" src="/img/image-13-1.png"/></div>
            <div className="text-wrapper-5">Liên hệ với</div>
            <img className="polygon" src="/img/polygon-4.svg"/>
          </div>
        </div>
      </div>
      <div className="group">
        <div className="overlap-2">
          <p className="trang-ch-s-n-ph-m-h">
            <span className="text-wrapper-6"
            >Trang chủ&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Sản
              phẩm&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Hàng nông
              sản&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            </span>
            <span className="text-wrapper-7">Gạo Tám Xoan Cần Thơ</span>
          </p>
          <img className="vector-11" src="/img/vector-36.svg"/>
          <img className="vector-12" src="/img/vector-36.svg"/>
          <img className="vector-13" src="/img/vector-36.svg"/>
        </div>
      </div>
      <div className="overlap-3">
        <div className="product-detail">
          <div className="overlap-group-wrapper">
            <div className="overlap-group-4"
              style={{
                backgroundImage: `url(/img/${paramId}/${paramId}1.${imgType || 'png'})`
              }}
            >
              <div className="ellipse"></div>
              <img className="polygon-2" src="/img/polygon-3.svg"/>
            </div>
          </div>
          <div className="frame">
            <div className="group-wrapper"
               style={{
                 backgroundImage: `url(/img/${paramId}/${paramId}1.${imgType || 'png'})`
               }}
            >
              <div className="overlap-group-play-wrapper"
                   style={videoStatus === "play" ? {display: "none"} : {display: "flex"}}
              >
                <div className="overlap-group-play"
                     onClick={startVideo}>
                  <div className="ellipse"></div>
                  <img className="polygon-2" src="/img/polygon-3.svg"/>
                </div>
              </div>
              <video
                id="video"
                className="animate-fadeIn"
                style={
                  videoStatus === "play" ? {display: "block"} : {display: "none"}
                }
                src={`/video/${paramId}.mp4`}
                autoPlay
                onPlay={startVideo}
                onEnded={onEnded}>
              
              </video>
              <div className="layer-wrapper"><img className="layer" src="/img/layer-3-6.svg"/></div>
            </div>
          </div>
          <p className="text-wrapper-8">{name}</p>
          <div className="group-2">
            <img className="star" src="/img/star-6-1.svg"/>
            <img className="star-2" src="/img/star-6-1.svg"/>
            <img className="star-3" src="/img/star-6-1.svg"/>
            <img className="star-4" src="/img/star-6-1.svg"/>
            <img className="star-5" src="/img/star-6-1.svg"/>
          </div>
          <div className="text-wrapper-9">Chia sẻ</div>
          <div className="overlap-4">
            <div className="overlap-5">
              <p className="g-o-t-m-xoan-l-m-t" style={{ whiteSpace: 'pre-line' }}>
                {longDesc}
              </p>
              <img className="line" src="/img/line-4.svg"/>
              <div className="flexcontainer">
                <p className="text">
                  <span className="text-wrapper-10">Hạn sử dụng 12 tháng<br/></span>
                </p>
                <p className="text">
                  <span className="text-wrapper-10">Miễn phí giao hàng<br/></span>
                </p>
                <p className="text">
                  <span className="text-wrapper-10">Hàng đảm bảo<br/></span>
                </p>
                <p className="text"><span className="text-wrapper-10">Hỗ trợ 24/7</span></p>
              </div>
              <img className="isolation-mode" src="/img/isolation-mode.svg"/>
              <div className="futae-contact">
                <div className="text-wrapper-11">Liên hệ với</div>
                <img className="image-2" src="/img/image-13-3.png"/>
                <button className="button-wrapper" onClick={handleOnClick}>
                  <button className="button">Tại đây</button>
                </button>
              </div>
            </div>
            <img className="isolation-mode-2" src="/img/isolation-mode-1.svg"/>
            <img className="isolation-mode-3" src="/img/isolation-mode-2.svg"/>
            <img className="isolation-mode-4" src="/img/isolation-mode-3.svg"/>
          </div>
          <p className="g-o-t-m-xoan-l-m-t-2">
            {detailDesc || DEFAULT_PRODUCT.detailDesc }
            
          </p>
          <img className="image-3" src={`/img/${paramId}/${paramId}4.${imgType || 'png'}`}/>
          <img className="line-2" src="/img/line-4.svg"/>
          <img className="line-3" src="/img/line-4.svg"/>
          <img className="line-4" src="/img/line-4.svg"/>
          <img className="line-5" src="/img/line-5.svg"/>
          <div className="div-wrapper">
            <div className="text-wrapper-12">Chi tiết sản phẩm</div>
          </div>
          <img className="image-4" src={`/img/${paramId}/${paramId}5.${imgType || 'png'}`}/>
          {id === ProductId.RICE ?
            <p className="g-o-rang-c-n-th-g-o" style={{ whiteSpace: 'pre-line' }}>
              <span className="text-wrapper-13">Gạo Rang Cần Thơ<br/></span>
              <span className="text-wrapper-14">
              Gạo Rang Cần Thơ là một loại gạo được&nbsp;&nbsp;sử dụng phổ biến để chế biến các món cơm rang, như cơm
              chiên, cơm rang&nbsp;&nbsp;dưa bò, cơm rang cá mặn và cơm rang thập cẩm. Hạt gạo Rang Cần Thơ
              tròn,&nbsp;&nbsp;mềm và thích hợp cho việc rang và xào.<br/><br
              /></span>
              <span className="text-wrapper-13">Gạo Cái Răng <br/></span>
              <span className="text-wrapper-14"
              >Gạo Cái Răng là một loại gạo đặc sản&nbsp;&nbsp;của Cần Thơ. Hạt gạo Cái Răng to, tròn và mềm. Nó có màu
              trắng tự nhiên&nbsp;&nbsp;và hương vị đặc trưng. Gạo Cái Răng thường được sử dụng để nấu
              cơm&nbsp;&nbsp;trắng, cháo, xôi và các món ăn truyền thống khác.</span
              >
            </p> :
            <p className="g-o-rang-c-n-th-g-o">
              {DEFAULT_PRODUCT.detailDesc}
            </p>
          }
          
          <Link to={"/cua-hang"}>
            <div className="overlap-6">
              <img className="layer-2" src="/img/layer-3-7.svg"/>
              <p className="c-a-h-ng-g-o-ch-t-m">
                <span className="text-wrapper-13">Cửa hàng chú Tám<br/></span>
                <span className="text-wrapper-15">Địa chỉ: Cái Răng, Cần Thơ</span>
              </p>
            </div>
          </Link>
          <img className="image-5" src="/img/image-18.png"/>
          <ChoosePrice handlePopup={onOpenPopup}/>
          <div className="tem-truy-xuat-nguon">
            <div className="text-wrapper-24">Cung cấp bởi ABC</div>
            <div className="overlap-7"><p className="text-wrapper-25">Tem truy xuất nguồn gốc</p></div>
            <div className="rectangle-wrapper">
              <div className="rectangle-4"></div>
            </div>
          </div>
        </div>
        <img className="rectangle-5" src={`/img/${paramId}/${paramId}1.${imgType || 'png'}`}/>
        <img className="rectangle-6" src={`/img/${paramId}/${paramId}2.${imgType || 'png'}`}/>
        <img className="rectangle-7" src={`/img/${paramId}/${paramId}3.${imgType || 'png'}`}/>
        <img className="rectangle-8" src={`/img/${paramId}/${paramId}4.${imgType || 'png'}`}/>
        <div className="feature-products">
          <div className="c-c-s-n-ph-m-c-a-c-wrapper">
            <p className="text-wrapper-26">CÁC SẢN PHẨM CỦA CÙNG NHÀ BÁN HÀNG</p>
          </div>
          <div className="product">
            <img className="image-6" src="/img/image-coconut.png"/>
            <div className="text-wrapper-27">Dừa Bến Tre</div>
            <p className="text-wrapper-28">Dừa Tươi giàu dinh dưỡng, vừa tốt cho sức khỏe, tươi mát</p>
            <div className="text-wrapper-29">15.000đ</div>
            <img className="star-6" src="/img/star-6-1.svg"/>
            <img className="star-7" src="/img/star-6-1.svg"/>
            <img className="star-8" src="/img/star-6-1.svg"/>
            <img className="star-9" src="/img/star-6-1.svg"/>
            <img className="star-10" src="/img/star-6-1.svg"/>
            <button className="btn-2">
              <button className="button">Mua ngay</button>
            </button>
          </div>
          <div className="product-2">
            <img className="image-6" src="/img/image-7-8.png"/>
            <div className="text-wrapper-27">Bí đỏ hồ lô</div>
            <p className="text-wrapper-28">Bí đỏ hồ lô dẻo, ngon, chất lượng, giàu dinh dưỡng</p>
            <div className="text-wrapper-29">8.000đ</div>
            <img className="star-6" src="/img/star-6-1.svg"/>
            <img className="star-7" src="/img/star-6-1.svg"/>
            <img className="star-8" src="/img/star-6-1.svg"/>
            <img className="star-9" src="/img/star-6-1.svg"/>
            <img className="star-10" src="/img/star-6-1.svg"/>
            <button className="btn-2">
              <button className="button">Mua ngay</button>
            </button>
          </div>
          <div className="product-3">
            <img className="image-6" src="/img/image-7-9.png"/>
            <div className="text-wrapper-27">Dưa hấu Long An</div>
            <p className="text-wrapper-28">
              Vị thuốc giải nhiệt ngày hè. Dưa vỏ xanh mỏng, cuống nhỏ, mọng nước, thanh mát
            </p>
            <div className="text-wrapper-29">12.000đ</div>
            <img className="star-6" src="/img/star-6-1.svg"/>
            <img className="star-7" src="/img/star-6-1.svg"/>
            <img className="star-8" src="/img/star-6-1.svg"/>
            <img className="star-9" src="/img/star-6-1.svg"/>
            <img className="star-10" src="/img/star-6-1.svg"/>
            <button className="btn-2">
              <button className="button">Mua ngay</button>
            </button>
          </div>
          <div className="product-4">
            <img className="image-6" src="/img/image-7-10.png"/>
            <div className="text-wrapper-27">Hành lá</div>
            <p className="text-wrapper-28">Hành lá – loại rau gia vị vô cùng bổ dưỡng</p>
            <div className="text-wrapper-29">10.000đ</div>
            <img className="star-6" src="/img/star-6-1.svg"/>
            <img className="star-7" src="/img/star-6-1.svg"/>
            <img className="star-8" src="/img/star-6-1.svg"/>
            <img className="star-9" src="/img/star-6-1.svg"/>
            <img className="star-10" src="/img/star-6-1.svg"/>
            <button className="btn-2">
              <button className="button">Mua ngay</button>
            </button>
          </div>
          <div className="product-5">
            <img className="image-6" src="/img/image-7-11.png"/>
            <div className="text-wrapper-27">Bắp cải trắng</div>
            <p className="text-wrapper-28">Bắp cải là loại rau bổ dưỡng vừa tốt cho sức khỏe, chứa nhiều vitamin C,
              kali</p>
            <div className="text-wrapper-29">15.000đ</div>
            <img className="star-6" src="/img/star-6-1.svg"/>
            <img className="star-7" src="/img/star-6-1.svg"/>
            <img className="star-8" src="/img/star-6-1.svg"/>
            <img className="star-9" src="/img/star-6-1.svg"/>
            <img className="star-10" src="/img/star-6-1.svg"/>
            <button className="btn-2">
              <button className="button">Mua ngay</button>
            </button>
          </div>
          <img className="line-6" src="/img/line-5-1.svg"/>
        </div>
      </div>
      <div className="footer">
        <div className="USP">
          <img className="layer-3" src="/img/layer-3-8.svg"/>
          <div className="giao-h-ng-mi-n-ph">GIAO HÀNG NHANH CHÓNG, TIẾT KIỆM</div>
          <p className="ch-nh-s-ch-i-tr-linh">CHÍNH SÁCH ĐỔI TRẢ LINH HOẠT</p>
          <div className="h-tr">HỖ TRỢ 24/7</div>
          <p className="text-wrapper-30">Hỗ trợ bà con nông dân giao hàng nhanh chóng khắp cả nước.</p>
          <p className="text-wrapper-31">Giúp bà con nông dân và người tiêu dùng đổi trả thuận tiện hơn</p>
          <p className="text-wrapper-32">Đội ngũ hỗ trợ bà con 24/7 nhanh chóng, tức thời</p>
          <img className="layer-4" src="/img/layer-3-9.svg"/>
          <img className="layer-5" src="/img/layer-3-10.svg"/>
        </div>
        <div className="footer-2">
          <div className="group-3">
            <div className="overlap-group-5">
              <div className="logo-2">
                <img className="img" src="/img/grocery-cart-1-2.png"/>
                <div className="text-wrapper-33">Funong</div>
                <div className="text-wrapper-34">.vn</div>
              </div>
              <img className="img" src="/img/grocery-cart-white-1.png"/>
            </div>
          </div>
          <div className="overlap-8">
            <p className="text-wrapper-35">
              Funong.vn là cầu nối giúp bà con nông dân có thể đưa sản phẩm của mình tới tay người tiêu dùng một cách dễ
              dàng và thuận tiện. Bà con nông dân đỡ được nỗi lo về chi phí vận chuyển cũng như chi phí bán hàng mà vẫn
              đạt được hiệu quả cao nhất
            </p>
            <p className="text-wrapper-36">223 Trần Hưng Đạo, Quận 1, TPHCM</p>
          </div>
          <img className="layer-6" src="/img/layer-3-11.svg"/>
          <img className="layer-7" src="/img/layer-3-12.svg"/>
          <div className="text-wrapper-37">1900 6067</div>
          <div className="flexcontainer-i">
            <p className="text-i">
              <span className="text-wrapper-38">HỖ TRỢ KHÁCH HÀNG</span>
              <span className="text-wrapper-39">&nbsp;&nbsp; <br/></span>
            </p>
            <p className="text-i">
              <span className="text-wrapper-39">Chính sách giao hàng&nbsp;&nbsp;&nbsp;&nbsp; <br/></span>
            </p>
            <p className="text-i">
              <span className="text-wrapper-39">Chính sách đổi trả&nbsp;&nbsp;&nbsp;&nbsp; <br/></span>
            </p>
            <p className="text-i">
              <span className="text-wrapper-39">Chính sách thành viên&nbsp;&nbsp;&nbsp;&nbsp;<br/></span>
            </p>
            <p className="text-i">
              <span className="text-wrapper-39">Hướng dẫn mua hàng và hoàn tiền&nbsp;&nbsp;&nbsp;&nbsp; <br/></span>
            </p>
            <p className="text-i">
              <span className="text-wrapper-39">Chính sách xuất hóa đơn đỏ&nbsp;&nbsp;&nbsp;&nbsp; <br/></span>
            </p>
            <p className="text-i"><span className="text-wrapper-39">Giấy chứng nhận Vệ sinh ATTP</span></p>
          </div>
          <div className="i-t-c-v-n-chuy-n">ĐỐI TÁC VẬN CHUYỂN</div>
          <div className="i-t-c-thanh-to-n">ĐỐI TÁC THANH TOÁN</div>
          <div className="group-4">
            <div className="goo-pay">GOOPAY</div>
            <img className="layer-8" src="/img/layer-3-13.svg"/>
          </div>
          <div className="flexcontainer-2">
            <p className="text-i">
              <span className="text-wrapper-39"><br/></span>
            </p>
            <p className="text-i">
              <span className="text-wrapper-39"><br/></span>
            </p>
            <p className="text-i">
              <span className="text-wrapper-39">Hướng dẫn mua hàng <br/></span>
            </p>
            <p className="text-i">
              <span className="text-wrapper-39">Hóa đơn điện tử <br/></span>
            </p>
            <p className="text-i">
              <span className="text-wrapper-39">Chính sách khách hàng <br/></span>
            </p>
            <p className="text-i">
              <span className="text-wrapper-39">Tích điểm <br/></span>
            </p>
            <p className="text-i"><span className="text-wrapper-39">Cảnh báo giả mạo</span></p>
          </div>
          <div className="image-wrapper"><img className="image-7" src="/img/image-13.png"/></div>
          <img className="image-8" src="/img/image-12.png"/>
        </div>
      </div>
      <div className="feature-products-2">
        <div className="c-c-s-n-ph-m-t-ng-t-wrapper"><p className="text-wrapper-26">CÁC SẢN PHẨM TƯƠNG TỰ</p></div>
        <div className="product">
          <img className="image-6" src="/img/image-coconut.png"/>
          <div className="text-wrapper-27">Dừa Bến Tre</div>
          <p className="text-wrapper-28">Dừa Tươi giàu dinh dưỡng, vừa tốt cho sức khỏe, tươi mát</p>
          <div className="text-wrapper-29">15.000đ</div>
          <img className="star-6" src="/img/star-6-1.svg"/>
          <img className="star-7" src="/img/star-6-1.svg"/>
          <img className="star-8" src="/img/star-6-1.svg"/>
          <img className="star-9" src="/img/star-6-1.svg"/>
          <img className="star-10" src="/img/star-6-1.svg"/>
          <button className="btn-2">
            <button className="button">Mua ngay</button>
          </button>
        </div>
        <div className="product-2">
          <img className="image-6" src="/img/image-7-8.png"/>
          <div className="text-wrapper-27">Bí đỏ hồ lô</div>
          <p className="text-wrapper-28">Bí đỏ hồ lô dẻo, ngon, chất lượng, giàu dinh dưỡng</p>
          <div className="text-wrapper-29">8.000đ</div>
          <img className="star-6" src="/img/star-6-1.svg"/>
          <img className="star-7" src="/img/star-6-1.svg"/>
          <img className="star-8" src="/img/star-6-1.svg"/>
          <img className="star-9" src="/img/star-6-1.svg"/>
          <img className="star-10" src="/img/star-6-1.svg"/>
          <button className="btn-2">
            <button className="button">Mua ngay</button>
          </button>
        </div>
        <div className="product-3">
          <img className="image-6" src="/img/image-7-9.png"/>
          <div className="text-wrapper-27">Dưa hấu Long An</div>
          <p className="text-wrapper-28">Vị thuốc giải nhiệt ngày hè. Dưa vỏ xanh mỏng, cuống nhỏ, mọng nước, thanh
            mát</p>
          <div className="text-wrapper-29">12.000đ</div>
          <img className="star-6" src="/img/star-6-1.svg"/>
          <img className="star-7" src="/img/star-6-1.svg"/>
          <img className="star-8" src="/img/star-6-1.svg"/>
          <img className="star-9" src="/img/star-6-1.svg"/>
          <img className="star-10" src="/img/star-6-1.svg"/>
          <button className="btn-2">
            <button className="button">Mua ngay</button>
          </button>
        </div>
        <div className="product-4">
          <img className="image-6" src="/img/image-7-10.png"/>
          <div className="text-wrapper-27">Hành lá</div>
          <p className="text-wrapper-28">Hành lá – loại rau gia vị vô cùng bổ dưỡng</p>
          <div className="text-wrapper-29">10.000đ</div>
          <img className="star-6" src="/img/star-6-1.svg"/>
          <img className="star-7" src="/img/star-6-1.svg"/>
          <img className="star-8" src="/img/star-6-1.svg"/>
          <img className="star-9" src="/img/star-6-1.svg"/>
          <img className="star-10" src="/img/star-6-1.svg"/>
          <button className="btn-2">
            <button className="button">Mua ngay</button>
          </button>
        </div>
        <div className="product-5">
          <img className="image-6" src="/img/image-7-11.png"/>
          <div className="text-wrapper-27">Bắp cải trắng</div>
          <p className="text-wrapper-28">Bắp cải là loại rau bổ dưỡng vừa tốt cho sức khỏe, chứa nhiều vitamin C,
            kali</p>
          <div className="text-wrapper-29">15.000đ</div>
          <img className="star-6" src="/img/star-6-1.svg"/>
          <img className="star-7" src="/img/star-6-1.svg"/>
          <img className="star-8" src="/img/star-6-1.svg"/>
          <img className="star-9" src="/img/star-6-1.svg"/>
          <img className="star-10" src="/img/star-6-1.svg"/>
          <button className="btn-2">
            <button className="button">Mua ngay</button>
          </button>
        </div>
        <img className="line-7" src="/img/line-5-2.svg"/>
      </div>
    </div>
  );
};
