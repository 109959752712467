import React, {useEffect} from "react";
import "./style.css";
import {Link} from "react-router-dom";


export const ManageProduct = () => {
  
  return (
    <div className="frame-manage-product animate-fadeIn">
      <div className="div">
        <div className="text-wrapper">Sắp xếp theo</div>
        <div className="ten-sp">
          <div className="text-wrapper-2">Sản phẩm mới nhất</div>
          <img className="vector" src="/img/provider/manage_product/vector-1.svg"/>
        </div>
        <div className="ten-sp-2">
          <p className="text-wrapper-2">Tìm trong 102 sản phẩm</p>
          <img className="group" src="/img/provider/manage_product/group-28.png"/>
        </div>
      </div>
      <Link to={"/nha-cung-cap/tao-san-pham"}>
        <div className="div-wrapper"><p className="p">+ Thêm sản phẩm mới</p></div>
      </Link>
      <div className="div-2">
        <div className="text-wrapper-3-mp">Đang hiển thị</div>
        <div className="text-wrapper-4-mp">Đang chờ duyệt (5)</div>
        <div className="text-wrapper-5-mp">Đã ẩn (3)</div>
        <div className="rectangle"></div>
        <img className="line" src="/img/provider/manage_product/line-4.svg"/>
        <div className="ban-le">
          <img className="image" src="/img/provider/manage_product/image-12-1.png"/>
          <div className="group-2">
            <p className="text-wrapper-6">Gạo Tám Xoan Cần Thơ</p>
            <div className="text-wrapper-7">35.000 đ</div>
          </div>
          <div className="group-3">
            <div className="text-wrapper-8">50 Tồn kho</div>
            <img className="img" src="/img/provider/manage_product/group-37.png"/>
          </div>
          <div className="group-4">
            <img className="img" src="/img/provider/manage_product/group-38.png"/>
            <div className="text-wrapper-9">10 Lượt bán</div>
          </div>
          <div className="overlap-group-wrapper">
            <div className="overlap-group">
              <div className="text-wrapper-10">Bán lẻ</div>
            </div>
          </div>
          <div className="text-wrapper-11">Ngày đăng: 15/05/2024</div>
          <div className="edit-btn">
            <div className="overlap">
              <img className="layer" src="/img/provider/manage_product/layer-3-2.svg"/>
              <div className="text-wrapper-12">Chỉnh sửa</div>
            </div>
          </div>
          <div className="overlap-wrapper">
            <div className="overlap">
              <div className="text-wrapper-13">Ẩn</div>
              <img className="group-5" src="/img/provider/manage_product/group-35-1.png"/>
            </div>
          </div>
          <img className="line-2" src="/img/provider/manage_product/line-5-1.svg"/>
        </div>
        <div className="ban-le-2">
          <img className="image" src="/img/provider/manage_product/image-12-1.png"/>
          <div className="group-2">
            <p className="text-wrapper-6">Gạo Tám Xoan Cần Thơ</p>
            <div className="text-wrapper-7">35.000 đ</div>
          </div>
          <div className="group-3">
            <div className="text-wrapper-8">50 Tồn kho</div>
            <img className="img" src="/img/provider/manage_product/group-37.png"/>
          </div>
          <div className="group-4">
            <img className="img" src="/img/provider/manage_product/group-38.png"/>
            <div className="text-wrapper-9">10 Lượt bán</div>
          </div>
          <div className="overlap-group-wrapper">
            <div className="overlap-group">
              <div className="text-wrapper-10">Bán lẻ</div>
            </div>
          </div>
          <div className="text-wrapper-11">Ngày đăng: 15/05/2024</div>
          <div className="edit-btn">
            <div className="overlap">
              <img className="layer" src="/img/provider/manage_product/layer-3-3.svg"/>
              <div className="text-wrapper-12">Chỉnh sửa</div>
            </div>
          </div>
          <div className="overlap-wrapper">
            <div className="overlap">
              <div className="text-wrapper-13">Ẩn</div>
              <img className="group-5" src="/img/provider/manage_product/group-35-1.png"/>
            </div>
          </div>
          <img className="line-2" src="/img/provider/manage_product/line-5-1.svg"/>
        </div>
        <div className="ban-le-3">
          <img className="image" src="/img/provider/manage_product/image-12-1.png"/>
          <div className="group-2">
            <p className="text-wrapper-6">Gạo Tám Xoan Cần Thơ</p>
            <div className="text-wrapper-7">35.000 đ</div>
          </div>
          <div className="group-3">
            <div className="text-wrapper-8">50 Tồn kho</div>
            <img className="img" src="/img/provider/manage_product/group-37.png"/>
          </div>
          <div className="group-4">
            <img className="img" src="/img/provider/manage_product/group-38.png"/>
            <div className="text-wrapper-9">10 Lượt bán</div>
          </div>
          <div className="overlap-group-wrapper">
            <div className="overlap-group">
              <div className="text-wrapper-10">Bán lẻ</div>
            </div>
          </div>
          <div className="text-wrapper-11">Ngày đăng: 15/05/2024</div>
          <div className="edit-btn">
            <div className="overlap">
              <img className="layer" src="/img/provider/manage_product/layer-3-4.svg"/>
              <div className="text-wrapper-12">Chỉnh sửa</div>
            </div>
          </div>
          <div className="overlap-wrapper">
            <div className="overlap">
              <div className="text-wrapper-13">Ẩn</div>
              <img className="group-5" src="/img/provider/manage_product/group-35-1.png"/>
            </div>
          </div>
          <img className="line-2" src="/img/provider/manage_product/line-5-1.svg"/>
        </div>
        <div className="ban-le-4">
          <img className="image" src="/img/provider/manage_product/image-12-1.png"/>
          <div className="group-2">
            <p className="text-wrapper-6">Gạo Tám Xoan Cần Thơ</p>
            <div className="text-wrapper-7">35.000 đ</div>
          </div>
          <div className="group-3">
            <div className="text-wrapper-8">50 Tồn kho</div>
            <img className="img" src="/img/provider/manage_product/group-37.png"/>
          </div>
          <div className="group-4">
            <img className="img" src="/img/provider/manage_product/group-38.png"/>
            <div className="text-wrapper-9">10 Lượt bán</div>
          </div>
          <div className="overlap-group-wrapper">
            <div className="overlap-group">
              <div className="text-wrapper-10">Bán lẻ</div>
            </div>
          </div>
          <div className="text-wrapper-11">Ngày đăng: 15/05/2024</div>
          <div className="edit-btn">
            <div className="overlap">
              <img className="layer" src="/img/provider/manage_product/layer-3-5.svg"/>
              <div className="text-wrapper-12">Chỉnh sửa</div>
            </div>
          </div>
          <div className="overlap-wrapper">
            <div className="overlap">
              <div className="text-wrapper-13">Ẩn</div>
              <img className="group-5" src="/img/provider/manage_product/group-35-1.png"/>
            </div>
          </div>
          <img className="line-2" src="/img/provider/manage_product/line-5-1.svg"/>
        </div>
        <div className="ban-le-5">
          <img className="image" src="/img/provider/manage_product/image-12-1.png"/>
          <div className="group-2">
            <p className="text-wrapper-6">Gạo Tám Xoan Cần Thơ</p>
            <div className="text-wrapper-7">35.000 đ</div>
          </div>
          <div className="group-3">
            <div className="text-wrapper-8">50 Tồn kho</div>
            <img className="img" src="/img/provider/manage_product/group-37.png"/>
          </div>
          <div className="group-4">
            <img className="img" src="/img/provider/manage_product/group-38.png"/>
            <div className="text-wrapper-9">10 Lượt bán</div>
          </div>
          <div className="overlap-group-wrapper">
            <div className="overlap-group">
              <div className="text-wrapper-10">Bán lẻ</div>
            </div>
          </div>
          <div className="text-wrapper-11">Ngày đăng: 15/05/2024</div>
          <div className="edit-btn">
            <div className="overlap">
              <img className="layer" src="/img/provider/manage_product/layer-3-6.svg"/>
              <div className="text-wrapper-12">Chỉnh sửa</div>
            </div>
          </div>
          <div className="overlap-wrapper">
            <div className="overlap">
              <div className="text-wrapper-13">Ẩn</div>
              <img className="group-5" src="/img/provider/manage_product/group-35-1.png"/>
            </div>
          </div>
          <img className="line-2" src="/img/provider/manage_product/line-5-1.svg"/>
        </div>
        <div className="ban-si">
          <img className="image" src="/img/provider/manage_product/image-12.png"/>
          <div className="group-2">
            <p className="text-wrapper-6">Gạo Tám Xoan Cần Thơ</p>
            <div className="text-wrapper-7">20.000 - 35.000 đ</div>
          </div>
          <div className="group-6">
            <div className="text-wrapper-14">10 Lượt liên hệ</div>
            <img className="vector-2" src="/img/provider/manage_product/vector.svg"/>
          </div>
          <div className="group-7">
            <div className="text-wrapper-15">50 Lượt xem</div>
            <img className="group-8" src="/img/provider/manage_product/group-34.png"/>
          </div>
          <div className="overlap-group-wrapper">
            <div className="overlap-group-2">
              <div className="text-wrapper-16">Bán sỉ</div>
            </div>
          </div>
          <div className="text-wrapper-11">Ngày đăng: 15/05/2024</div>
          <div className="edit-btn">
            <div className="overlap">
              <img className="layer" src="/img/provider/manage_product/layer-3-7.svg"/>
              <div className="text-wrapper-12">Chỉnh sửa</div>
            </div>
          </div>
          <div className="overlap-wrapper">
            <div className="overlap">
              <div className="text-wrapper-13">Ẩn</div>
              <img className="group-5" src="/img/provider/manage_product/group-35-1.png"/>
            </div>
          </div>
          <img className="line-2" src="/img/provider/manage_product/line-5-1.svg"/>
        </div>
        <div className="ban-si-2">
          <img className="image" src="/img/provider/manage_product/image-12.png"/>
          <div className="group-2">
            <p className="text-wrapper-6">Gạo Tám Xoan Cần Thơ</p>
            <div className="text-wrapper-7">20.000 - 35.000 đ</div>
          </div>
          <div className="group-6">
            <div className="text-wrapper-14">10 Lượt liên hệ</div>
            <img className="vector-2" src="/img/provider/manage_product/vector.svg"/>
          </div>
          <div className="group-7">
            <div className="text-wrapper-15">50 Lượt xem</div>
            <img className="group-8" src="/img/provider/manage_product/group-34.png"/>
          </div>
          <div className="overlap-group-wrapper">
            <div className="overlap-group-2">
              <div className="text-wrapper-16">Bán sỉ</div>
            </div>
          </div>
          <div className="text-wrapper-11">Ngày đăng: 15/05/2024</div>
          <div className="edit-btn">
            <div className="overlap">
              <img className="layer" src="/img/provider/manage_product/layer-3-8.svg"/>
              <div className="text-wrapper-12">Chỉnh sửa</div>
            </div>
          </div>
          <div className="overlap-wrapper">
            <div className="overlap">
              <div className="text-wrapper-13">Ẩn</div>
              <img className="group-5" src="/img/provider/manage_product/group-35-1.png"/>
            </div>
          </div>
          <img className="line-2" src="/img/provider/manage_product/line-5-1.svg"/>
        </div>
        <div className="ban-si-3">
          <img className="image" src="/img/provider/manage_product/image-12.png"/>
          <div className="group-2">
            <p className="text-wrapper-6">Gạo Tám Xoan Cần Thơ</p>
            <div className="text-wrapper-7">20.000 - 35.000 đ</div>
          </div>
          <div className="group-6">
            <div className="text-wrapper-14">10 Lượt liên hệ</div>
            <img className="vector-2" src="/img/provider/manage_product/vector.svg"/>
          </div>
          <div className="group-7">
            <div className="text-wrapper-15">50 Lượt xem</div>
            <img className="group-8" src="/img/provider/manage_product/group-34.png"/>
          </div>
          <div className="overlap-group-wrapper">
            <div className="overlap-group-2">
              <div className="text-wrapper-16">Bán sỉ</div>
            </div>
          </div>
          <div className="text-wrapper-11">Ngày đăng: 15/05/2024</div>
          <div className="edit-btn">
            <div className="overlap">
              <img className="layer" src="/img/provider/manage_product/layer-3-9.svg"/>
              <div className="text-wrapper-12">Chỉnh sửa</div>
            </div>
          </div>
          <div className="overlap-wrapper">
            <div className="overlap">
              <div className="text-wrapper-13">Ẩn</div>
              <img className="group-5" src="/img/provider/manage_product/group-35-1.png"/>
            </div>
          </div>
          <img className="line-2" src="/img/provider/manage_product/line-5-1.svg"/>
        </div>
        <div className="ban-si-4">
          <img className="image" src="/img/provider/manage_product/image-12.png"/>
          <div className="group-2">
            <p className="text-wrapper-6">Gạo Tám Xoan Cần Thơ</p>
            <div className="text-wrapper-7">20.000 - 35.000 đ</div>
          </div>
          <div className="group-6">
            <div className="text-wrapper-14">10 Lượt liên hệ</div>
            <img className="vector-2" src="/img/provider/manage_product/vector.svg"/>
          </div>
          <div className="group-7">
            <div className="text-wrapper-15">50 Lượt xem</div>
            <img className="group-8" src="/img/provider/manage_product/group-34.png"/>
          </div>
          <div className="overlap-group-wrapper">
            <div className="overlap-group-2">
              <div className="text-wrapper-16">Bán sỉ</div>
            </div>
          </div>
          <div className="text-wrapper-11">Ngày đăng: 15/05/2024</div>
          <div className="edit-btn">
            <div className="overlap">
              <img className="layer" src="/img/provider/manage_product/layer-3-10.svg"/>
              <div className="text-wrapper-12">Chỉnh sửa</div>
            </div>
          </div>
          <div className="overlap-wrapper">
            <div className="overlap">
              <div className="text-wrapper-13">Ẩn</div>
              <img className="group-5" src="/img/provider/manage_product/group-35-1.png"/>
            </div>
          </div>
          <img className="line-2" src="/img/provider/manage_product/line-5-1.svg"/>
        </div>
        <div className="ban-si-5">
          <img className="image" src="/img/provider/manage_product/image-12.png"/>
          <div className="group-2">
            <p className="text-wrapper-6">Gạo Tám Xoan Cần Thơ</p>
            <div className="text-wrapper-7">20.000 - 35.000 đ</div>
          </div>
          <div className="group-6">
            <div className="text-wrapper-14">10 Lượt liên hệ</div>
            <img className="vector-2" src="/img/provider/manage_product/vector.svg"/>
          </div>
          <div className="group-7">
            <div className="text-wrapper-15">50 Lượt xem</div>
            <img className="group-8" src="/img/provider/manage_product/group-34.png"/>
          </div>
          <div className="overlap-group-wrapper">
            <div className="overlap-group-2">
              <div className="text-wrapper-16">Bán sỉ</div>
            </div>
          </div>
          <div className="text-wrapper-11">Ngày đăng: 15/05/2024</div>
          <div className="edit-btn">
            <div className="overlap">
              <img className="layer" src="/img/provider/manage_product/layer-3-11.svg"/>
              <div className="text-wrapper-12">Chỉnh sửa</div>
            </div>
          </div>
          <div className="overlap-wrapper">
            <div className="overlap">
              <div className="text-wrapper-13">Ẩn</div>
              <img className="group-5" src="/img/provider/manage_product/group-35-1.png"/>
            </div>
          </div>
          <img className="line-2" src="/img/provider/manage_product/line-5-1.svg"/>
        </div>
        <div className="overlap-2">
          <div className="rectangle-2"></div>
          <p className="trang">
            <span className="span">Trang&nbsp;&nbsp;&nbsp;&nbsp;</span>
            <span className="text-wrapper-17">1</span>
            <span className="span"
            >&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;2&nbsp;&nbsp;&nbsp;&nbsp;
              3&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;4&nbsp;&nbsp;&nbsp;&nbsp; 5</span
            >
          </p>
        </div>
        <img className="polygon" src="/img/provider/manage_product/polygon-8.svg"/>
      </div>
    </div>
  );
};
