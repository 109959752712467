

export const ProductId = {
	COCONUT: 'coconut',
	WATERMELON: 'watermelon',
	BASA: 'basa',
	CRAB: 'crab',
	SHRIMP: 'shrimp',
	PINEAPPLE: 'pineapple',
	RICE: 'rice',
	PUMPKIN: 'pumpkin',
	SPRING_ONION: 'spring_onion',
	CABBAGE: 'cabbage',
	SQUID: 'squid',
	MANTIS_SHRIMP: 'mantis_shrimp',
}

export const RICE = {
	id: "rice",
	price: "25.000đ",
	name: "Gạo Tám Xoan Cần Thơ",
	shopName: "Cửa hàng gạo chú Tám",
	imgPath: "/img/rice/rice1.png",
	desc: "Hạt gạo Tám Xoan dẹp, trắng và có mùi thơm đặc trưng",
	longDesc: "Gạo Tám Xoan là một loại gạo ngon và đặc biệt của Cần Thơ.  Hạt gạo Tám Xoan dẹp, trắng và có mùi thơm đặc trưng. Gạo này có độ dẻo  vừa phải và hương vị đậm đà. Nó thích hợp để nấu cơm trắng, xôi, cháo  và các món ăn khác.\n" +
		"\n" +
		"Gạo Cần Thơ được chăm sóc và trồng trọt trong các vùng đồng bằng Sông Cửu Long,  nơi có điều kiện tự nhiên thuận lợi cho việc trồng lúa. Nhờ vào quá  trình sản xuất cẩn thận và kiểm soát chất lượng, gạo nơi đây thường có  chất lượng cao, hạt gạo trắng, dẻo và mềm tạo nên sự khác biệt với các  loại gạo nơi khác.",
	
	detailDesc: "Gạo Tám Xoan là một loại gạo ngon và đặc biệt của Cần Thơ.  Hạt gạo Tám Xoan dẹp, trắng và có mùi thơm đặc trưng. Gạo này có độ dẻo  vừa phải và hương vị đậm đà. Nó thích hợp để nấu cơm trắng, xôi, cháo  và các món ăn khác.\n" +
		"\n" +
		"Gạo Cần Thơ được chăm sóc và trồng trọt trong các vùng đồng bằng Sông Cửu Long,  nơi có điều kiện tự nhiên thuận lợi cho việc trồng lúa. Nhờ vào quá  trình sản xuất cẩn thận và kiểm soát chất lượng, gạo nơi đây thường có  chất lượng cao, hạt gạo trắng, dẻo và mềm tạo nên sự khác biệt với các  loại gạo nơi khác."
}

export const DEFAULT_PRODUCT = {
	detailDesc: "Nông sản Cần Thơ được trồng trọt, chăm sóc ở điều kiện tốt, nên khi thu hoạch sản phẩm có cân nặng tốt, cầm chắc tay, đặc ruột, có mùi thơm lịu. Người tiêu dùng có thể an tâm lựa chọn mà không cần lo lắng về chất lượng sản phẩm. \n Nông sản Cần Thơ là nghiên liệu hàng đầu trong việc chế biến các món ăn, sản phẩm khác nhau. Các sản phẩm được làm từ nông sản Cần Thơ khi bán ra thị trường đều đạt lợi nhuận cao, có uy tín tốt với người tiêu dùng với chất lượng sản phẩm cao."
}

export const COCONUT = {
	id: ProductId.COCONUT,
	name: "Dừa Bến Tre",
	imgPath: "/img/image-coconut.png",
	shopName: "Cửa hàng dừa chú Tám",
	desc: "Dừa Tươi giàu dinh dưỡng, vừa tốt cho sức khỏe, tươi mát",
	price: '25.000đ'
}
export const WATERMELON = {
	id: ProductId.WATERMELON,
	price: "12.000đ",
	imgPath: "/img/watermelon/watermelon1.jpg",
	shopName: "Cửa hàng dưa chú Tám",
	imgType: "jpg",
	name: "Dưa hấu Long An",
	desc: "Dưa hấu Long An mát ngọt",
	longDesc: "Dưa hấu Cần Thơ tươi ngon, ngọt nước, tròn đều, cầm chắc tay. Nó thích hợp để làm các loại nước uống, các món tráng miệng.  Dưa hấu được chăm sóc và trồng tại Huyện Thới Lai, Thành phố Cần Thơ.Do điều kiện thích hợp, dưa hấu khi thu hoạch đều có kích cỡ tươi tròn, chất lượng tốt."
}

export const PUMPKIN = {
	id: ProductId.PUMPKIN,
	price: "8.000đ",
	imgPath: "/img/pumpkin/pumpkin1.jpg",
	shopName: "Cửa hàng bí chú Tám",
	imgType: "jpg",
	name: "Bí đỏ hồ lô",
	desc: "Bí đỏ hồ lô dẻo, ngon, chất lượng, giàu dinh dưỡng",
	longDesc: "Bí đỏ Cần Thơ có quả vỏ cứng, ruột đỏ, vị ngọt và thơm. Nó rất thích hôp để nấu nhiếu loại món ăn, bổ sung chất dinh dưỡng cho trẻ nhỏ. Do điều kiện chăm sóc tốt, bí đỏ Cần Thơ cầm nặng tay từ 2.5 – 7 kg, phần ruột có màu vàng đậm, đặc ruột."
}

export const SPRING_ONION = {
	id: ProductId.SPRING_ONION,
	price: "10.000đ",
	imgPath: "/img/spring_onion/spring_onion1.jpg",
	imgType: "jpg",
	shopName: "Cửa hàng hành chú Tám",
	name: "Hành lá",
	desc: "Hành lá – loại rau gia vị vô cùng bổ dưỡng",
	longDesc: "Hành lá Cần thơ có vị cay, ngọt, tính ấm, hương vị thơm dịu. Nó rất thích hợp để làm nguyên liệu khi nấu, giúp các món ăn đặm đà hơn. Hành lá được trồng và chăm sóc ở điều kiện tốt nhất, nên chất lượng của sản phẩm khi thu hoạch đều đạt chất lượng tốt."
}

export const CABBAGE = {
	id: ProductId.CABBAGE,
	price: "15.000đ",
	imgPath: "/img/cabbage/cabbage1.jpg",
	imgType: "jpg",
	shopName: "Cửa hàng chú Tám",
	name: "Bắp cải trắng",
	desc: "Bắp cải là loại rau bổ dưỡng vừa tốt cho sức khỏe, chứa nhiều vitamin C, kali",
	longDesc: "Bắp cải Cần Thơ luôn nặng cân, cầm chắc tay và giàu dinh dưỡng, Nó rất thích hợp để dùng làm nghiên liệu cho nhiều món ăn, cung cấp đầy đủ dưỡng chất cho cơ thể. Do điều kiện trồng trọt và chăm sóc tốt, bắp cải Cần Thơ có cuống nhỏ, lá bện, cuốn vào nhau và đầu bắp cải dài, khép kín"
}

export const PINEAPPLE = {
	id: ProductId.PINEAPPLE,
	price: "15.000đ",
	imgPath: "/img/pineapple/pineapple1.png",
	shopName: "Cửa hàng chú Tám",
	name: "Dứa Cần Thơ",
	desc: "Dứa Cần Thơ ngon, chất lượng, giàu dinh dưỡng",

}

export const BASA = {
	id: ProductId.BASA,
	price: "65.000đ",
	name: "Cá basa",
	imgPath: "/img/fish/basa.png",
	desc: "Cá basa chỉ sống chủ yếu ở sông nước chảy và được nuôi trong bè",
}

export const CRAB = {
	id: ProductId.CRAB,
	price: "350.000đ",
	name: "Cua Cà Mau",
	imgPath: "/img/fish/crab.png",
	desc: "Cua Cà Mau sản vật có giá trị dinh dưỡng cao - lựa chọn của các bà nội trợ",
}
export const SHRIMP = {
	id: ProductId.SHRIMP,
	price: "800.000đ",
	name: "Tôm sú khổng lồ Bến Tre",
	imgPath: "/img/fish/shrimp.png",
	desc: "Tôm sú khổng lồ có kích cỡ siêu khủng đang tạo nên một \"cơn sốt\"",
}

export const SQUID = {
	id: ProductId.SQUID,
	price: "410.000đ",
	name: "Mực lá một nắng",
	imgPath: "/img/fish/squid.png",
	desc: "Mực lá một nắng với lớp thịt dày, vị ngọt thanh và dẻo",
}

export const MANTIS_SHRIMP = {
	id: ProductId.MANTIS_SHRIMP,
	price: "150.000đ",
	name: "Tôm tít Cà Mau",
	imgPath: "/img/fish/mantis_shrimp.png",
	desc: "Tôm tít ở đây có kích thước khá lớn, thịt chắc, có độ dai",
}

export const COCONUT_CANDY = {
	id: "COCONUT_CANDY",
	price: "15.000đ",
	name: "Kẹo dừa Bến Tre",
	imgPath: "/img/specialty/coconut_candy.png",
	desc: "Loại kẹo đặc sản được nhiều khách du lịch trong và ngoài nước yêu thích",
}

export const BIA_CAKE = {
	id: "BIA_CAKE",
	price: "52.000đ",
	name: "Bánh bía Sóc Trăng",
	imgPath: "/img/specialty/bia_cale.png",
	desc: "Thưởng thức vài chiếc bánh  cùng với ngụm trà gừng, buôn đôi ba câu chuyện",
}

export const FRIED_SNAKE_FISH = {
	id: "BIA_CAKE",
	price: "80.000đ",
	name: "Khô cá lóc 1 nắng",
	imgPath: "/img/specialty/fried_snake_fish.png",
	desc: "Hay còn gọi là cá quả được phơi sấy giúp thịt cá săn lại kết hợp với gia vị",
}

export const BA_KHIA = {
	id: "BA_KHIA",
	price: "40.000đ",
	name: "Mắm ba khía",
	imgPath: "/img/specialty/ba_khia.png",
	desc: "Nghề làm Mắm là cả một tinh hoa ẩm thực được trau chuốt kỹ lưỡng",
}

export const FRIED_FRUIT = {
	id: "FRIED_FRUIT",
	price: "15.000đ",
	name: "Trái cây sấy",
	imgPath: "/img/specialty/fried_fruit.png",
	desc: "Trái cây sấy khô là món ăn được rất nhiều người ưa thích vì tiện lợi và dễ ăn",
}

export const FeaturedProducts = {
	id: 'featured_products',
	name: 'SẢN PHẨM NỔI BẬT',
	products: [RICE, WATERMELON, PUMPKIN, SPRING_ONION, CABBAGE]

}
export const ProductDetail = {
	[ProductId.RICE]: RICE,
	[ProductId.WATERMELON]: WATERMELON,
	[ProductId.PUMPKIN]: PUMPKIN,
	[ProductId.SPRING_ONION]: SPRING_ONION,
	[ProductId.CABBAGE]: CABBAGE,
	[ProductId.BASA]: BASA,
	[ProductId.PINEAPPLE]: PINEAPPLE,
}

export const SPECIALTY = {
	id: 'specialty',
	name: "ĐẶC SẢN",
	products: [COCONUT_CANDY, BIA_CAKE, FRIED_SNAKE_FISH, BA_KHIA, FRIED_FRUIT]
}

export const SEA_FOOD = {
	id: 'sea_food',
	name: 'THUỶ HẢI SẢN',
	products: [BASA, CRAB, SHRIMP, SQUID, MANTIS_SHRIMP]
}