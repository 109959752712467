import React, {useEffect} from "react";
import "./style.css";


export const ManageOrder = () => {
  
  return (
    <div className="frame-manage-order animate-fadeIn">
      <div className="div">
        <div className="text-wrapper-mo">Sắp xếp theo</div>
        <div className="ten-sp">
          <div className="text-wrapper-2">Sản phẩm mới nhất</div>
          <img className="vector" src="/img/provider/manage_order/vector.svg"/>
        </div>
        <div className="ten-sp-2">
          <p className="text-wrapper-2">Tìm trong 1008 đơn hàng</p>
          <img className="group" src="/img/provider/manage_order/group-28.png"/>
        </div>
      </div>
      <div className="div-2">
        <div className="text-wrapper-3">Tất cả</div>
        <div className="text-wrapper-4">Chờ xác nhận (5)</div>
        <div className="text-wrapper-5">Đang giao (5)</div>
        <div className="text-wrapper-6">Chờ lấy hàng (3)</div>
        <div className="text-wrapper-7">Đã giao (30)</div>
        <div className="rectangle"></div>
        <img className="line" src="/img/provider/manage_order/line-4.svg"/>
        <div className="group-2">
          <div className="overlap-group">
            <div className="rectangle-2"></div>
            <p className="trang">
              <span className="span">Trang&nbsp;&nbsp;&nbsp;&nbsp;</span>
              <span className="text-wrapper-8-mo">1</span>
              <span className="span"
              >&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;2&nbsp;&nbsp;&nbsp;&nbsp;
                3&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;4&nbsp;&nbsp;&nbsp;&nbsp; 5</span
              >
            </p>
          </div>
          <img className="polygon" src="/img/provider/manage_order/polygon-8.svg"/>
        </div>
        <div className="don-hang">
          <div className="overlap">
            <div className="text-wrapper-9">DH0011122333.03</div>
            <div className="text-wrapper-10">Ngày lên đơn: 05/07/2024</div>
            <p className="v-n-chuy-n-b-i-futa">
              Vận chuyển bởi: Futa Express&nbsp;&nbsp;|&nbsp;&nbsp;Mã vận đơn: FTE112233
            </p>
            <div className="overlap-group-wrapper">
              <div className="div-wrapper-mo">
                <div className="text-wrapper-11">Đang giao</div>
              </div>
            </div>
            <img className="layer" src="/img/provider/manage_order/layer-3.svg"/>
          </div>
          <p className="p">Thanh toán bằng: Ví GooPay</p>
          <div className="text-wrapper-12-mo">Thanh toán: 200.000đ</div>
          <div className="text-wrapper-13-mo">Đơn giá: 20.000đ</div>
          <div className="text-wrapper-14">Số lượng: 10kg</div>
          <p className="text-wrapper-15">Gạo Tám Xoan Cần Thơ</p>
          <div className="edit-btn-mo">
            <div className="overlap-2-mo">
              <div className="text-wrapper-16">Chi tiết</div>
              <img className="isolation-mode" src="/img/provider/manage_order/isolation-mode.svg"/>
            </div>
          </div>
          <img className="img" src="/img/provider/manage_order/line-5-6.svg"/>
        </div>
        <div className="don-hang-2">
          <div className="overlap">
            <p className="v-n-chuy-n-b-i-futa">
              Vận chuyển bởi: Futa Express&nbsp;&nbsp;|&nbsp;&nbsp;Mã vận đơn: FTE112233
            </p>
            <img className="layer" src="/img/provider/manage_order/layer-3-1.svg"/>
            <div className="text-wrapper-9">DH0011122333.03</div>
            <div className="text-wrapper-10">Ngày lên đơn: 05/07/2024</div>
            <div className="group-3">
              <div className="overlap-group-2">
                <div className="text-wrapper-17">Chờ lấy hàng</div>
              </div>
            </div>
          </div>
          <p className="p">Thanh toán bằng: Ví GooPay</p>
          <div className="text-wrapper-12-mo">Thanh toán: 200.000đ</div>
          <p className="text-wrapper-15">Gạo Tám Xoan Cần Thơ</p>
          <div className="edit-btn-mo">
            <div className="overlap-2-mo">
              <div className="text-wrapper-16">Chi tiết</div>
              <img className="isolation-mode" src="/img/provider/manage_order/isolation-mode-1.svg"/>
            </div>
          </div>
          <div className="text-wrapper-13-mo">Đơn giá: 20.000đ</div>
          <div className="text-wrapper-14">Số lượng: 10kg</div>
          <img className="img" src="/img/provider/manage_order/line-5-6.svg"/>
        </div>
        <div className="don-hang-3">
          <div className="overlap">
            <p className="v-n-chuy-n-b-i-futa">
              Vận chuyển bởi: Futa Express&nbsp;&nbsp;|&nbsp;&nbsp;Mã vận đơn: FTE112233
            </p>
            <img className="layer" src="/img/provider/manage_order/layer-3-2.svg"/>
            <div className="text-wrapper-9">DH0011122333.03</div>
            <div className="text-wrapper-10">Ngày lên đơn: 05/07/2024</div>
            <div className="group-3">
              <div className="overlap-group-2">
                <div className="text-wrapper-17">Chờ lấy hàng</div>
              </div>
            </div>
          </div>
          <p className="p">Thanh toán bằng: Ví GooPay</p>
          <div className="text-wrapper-12-mo">Thanh toán: 200.000đ</div>
          <p className="text-wrapper-15">Gạo Tám Xoan Cần Thơ</p>
          <div className="edit-btn-mo">
            <div className="overlap-2-mo">
              <div className="text-wrapper-16">Chi tiết</div>
              <img className="isolation-mode" src="/img/provider/manage_order/isolation-mode-2.svg"/>
            </div>
          </div>
          <div className="text-wrapper-13-mo">Đơn giá: 20.000đ</div>
          <div className="text-wrapper-14">Số lượng: 10kg</div>
          <img className="img" src="/img/provider/manage_order/line-5-6.svg"/>
        </div>
        <div className="don-hang-4">
          <div className="overlap">
            <p className="v-n-chuy-n-b-i-futa">
              Vận chuyển bởi: Futa Express&nbsp;&nbsp;|&nbsp;&nbsp;Mã vận đơn: FTE112233
            </p>
            <img className="layer" src="/img/provider/manage_order/layer-3-3.svg"/>
            <div className="text-wrapper-9">DH0011122333.03</div>
            <div className="text-wrapper-10">Ngày lên đơn: 05/07/2024</div>
            <div className="group-4">
              <div className="overlap-group-3">
                <div className="text-wrapper-18">Đã giao</div>
              </div>
            </div>
          </div>
          <p className="p">Thanh toán bằng: Ví GooPay</p>
          <div className="text-wrapper-12-mo">Thanh toán: 200.000đ</div>
          <p className="text-wrapper-15">Gạo Tám Xoan Cần Thơ</p>
          <div className="edit-btn-mo">
            <div className="overlap-2-mo">
              <div className="text-wrapper-16">Chi tiết</div>
              <img className="isolation-mode" src="/img/provider/manage_order/isolation-mode-3.svg"/>
            </div>
          </div>
          <div className="text-wrapper-13-mo">Đơn giá: 20.000đ</div>
          <div className="text-wrapper-14">Số lượng: 10kg</div>
          <img className="img" src="/img/provider/manage_order/line-5-6.svg"/>
        </div>
        <div className="don-hang-5">
          <div className="overlap">
            <p className="v-n-chuy-n-b-i-futa">
              Vận chuyển bởi: Futa Express&nbsp;&nbsp;|&nbsp;&nbsp;Mã vận đơn: FTE112233
            </p>
            <img className="layer" src="/img/provider/manage_order/layer-3-4.svg"/>
            <div className="text-wrapper-9">DH0011122333.03</div>
            <div className="text-wrapper-10">Ngày lên đơn: 05/07/2024</div>
            <div className="group-4">
              <div className="overlap-group-3">
                <div className="text-wrapper-18">Đã giao</div>
              </div>
            </div>
          </div>
          <p className="p">Thanh toán bằng: Ví GooPay</p>
          <div className="text-wrapper-12-mo">Thanh toán: 200.000đ</div>
          <p className="text-wrapper-15">Gạo Tám Xoan Cần Thơ</p>
          <div className="edit-btn-mo">
            <div className="overlap-2-mo">
              <div className="text-wrapper-16">Chi tiết</div>
              <img className="isolation-mode" src="/img/provider/manage_order/isolation-mode-4.svg"/>
            </div>
          </div>
          <div className="text-wrapper-13-mo">Đơn giá: 20.000đ</div>
          <div className="text-wrapper-14">Số lượng: 10kg</div>
          <img className="img" src="/img/provider/manage_order/line-5-6.svg"/>
        </div>
        <div className="don-hang-6">
          <div className="overlap">
            <div className="text-wrapper-9">DH0011122333.03</div>
            <div className="text-wrapper-10">Ngày lên đơn: 05/07/2024</div>
            <div className="group-5">
              <div className="overlap-group-4">
                <div className="text-wrapper-19">Chờ xác nhận</div>
              </div>
            </div>
          </div>
          <p className="p">Thanh toán bằng: Ví GooPay</p>
          <div className="text-wrapper-12-mo">Thanh toán: 200.000đ</div>
          <div className="text-wrapper-13-mo">Đơn giá: 20.000đ</div>
          <div className="text-wrapper-14">Số lượng: 10kg</div>
          <p className="text-wrapper-15">Gạo Tám Xoan Cần Thơ</p>
          <div className="edit-btn-2-mo">
            <div className="overlap-3-mo">
              <div className="text-wrapper-20">Xác nhận</div>
              <img className="layer-2" src="/img/provider/manage_order/layer-3-6.svg"/>
            </div>
            <div className="overlap-4">
              <img className="layer-3" src="/img/provider/manage_order/layer-3-5.svg"/>
              <div className="text-wrapper-20">Huỷ</div>
            </div>
          </div>
          <img className="img" src="/img/provider/manage_order/line-5-6.svg"/>
        </div>
        <div className="don-hang-7">
          <div className="overlap">
            <div className="text-wrapper-9">DH0011122333.03</div>
            <div className="text-wrapper-10">Ngày lên đơn: 05/07/2024</div>
            <div className="group-5">
              <div className="overlap-group-4">
                <div className="text-wrapper-19">Chờ xác nhận</div>
              </div>
            </div>
          </div>
          <p className="p">Thanh toán bằng: Ví GooPay</p>
          <div className="text-wrapper-12-mo">Thanh toán: 200.000đ</div>
          <div className="text-wrapper-13-mo">Đơn giá: 20.000đ</div>
          <div className="text-wrapper-14">Số lượng: 10kg</div>
          <p className="text-wrapper-15">Gạo Tám Xoan Cần Thơ</p>
          <div className="edit-btn-2-mo">
            <div className="overlap-3-mo">
              <div className="text-wrapper-20">Xác nhận</div>
              <img className="layer-2" src="/img/provider/manage_order/layer-3-8.svg"/>
            </div>
            <div className="overlap-4">
              <img className="layer-3" src="/img/provider/manage_order/layer-3-7.svg"/>
              <div className="text-wrapper-20">Huỷ</div>
            </div>
          </div>
          <img className="img" src="/img/provider/manage_order/line-5-6.svg"/>
        </div>
      </div>
    </div>
  );
};
