/*
We're constantly improving the code you see. 
Please share your feedback here: https://form.asana.com/?k=uvp-HPgd3_hyoXRBw1IcNg&d=1152665201300829
*/

import PropTypes from "prop-types";
import React from "react";
import { useReducer } from "react";
import "./style.css";

export const SlideMainBanner = ({ property1, className, iconPhoneClassName, iconPhone = "/img/icon-phone-2.svg" }) => {
  const [state, dispatch] = useReducer(reducer, {
    property1: property1 || "default",
  });

  return (
    <div className={`slide-main-banner ${state.property1} ${className}`}>
      {state.property1 === "default" && (
        <>
          <div className="main-banner">
            <img className="img" alt="Image" src="/img/main-1512x400-1-1.png" />
            <div className="text-wrapper">Trái cây tươi</div>
            <div className="div">Siêu Deal Giá tốt</div>
            <div className="group">
              <div className="overlap-group">
                <p className="p">Liên hệ đặt hàng ngay</p>
              </div>
              <img className={`icon-phone ${iconPhoneClassName}`} alt="Icon phone" src="/img/icon-phone-4.png" />
            </div>
          </div>
          <div className="overlap">
            <div className="group-wrapper">
              <div className="group-2">
                <p className="text-wrapper-2">Gạo chú Tám Cần Thơ</p>
                <div className="text-wrapper-3">Gạo Ngon đặc biệt</div>
                <div className="overlap-group-2">
                  <img className="icon-phone-2" alt="Icon phone" src={iconPhone} />
                  <p className="p">Liên hệ đặt hàng ngay</p>
                </div>
              </div>
            </div>
            <div className="frame">
              <div
                className="ellipse"
                onClick={() => {
                  dispatch("click");
                }}
              />
              <div className="rectangle" />
            </div>
          </div>
        </>
      )}

      {state.property1 === "variant-2" && (
        <div className="overlap-2">
          <div className="overlap-wrapper">
            <div className="overlap-3">
              <div className="text-wrapper">Trái cây tươi</div>
              <div className="div">Siêu Deal Giá tốt</div>
              <div className="group">
                <div className="overlap-group-3">
                  <img className="icon-phone-2" alt="Icon phone" src="/img/icon-phone-2.svg" />
                  <p className="p">Liên hệ đặt hàng ngay</p>
                </div>
              </div>
            </div>
          </div>
          <div className="group-3">
            <img className="img" alt="Main" src="/img/main-1512x400-1-1.png" />
            <div className="group-4">
              <p className="text-wrapper-2">Gạo chú Tám Cần Thơ</p>
              <div className="text-wrapper-3">Gạo Ngon đặc biệt</div>
              <div className="overlap-group-2">
                <p className="p">Liên hệ đặt hàng ngay</p>
              </div>
              <img className="icon-phone-3" alt="Icon phone" src="/img/icon-phone-4.png" />
            </div>
          </div>
          <div className="frame">
            <div
              className="ellipse-2"
              onClick={() => {
                dispatch("click_915");
              }}
            />
            <div className="rectangle-2" />
          </div>
        </div>
      )}
    </div>
  );
};

function reducer(state, action) {
  switch (action) {
    case "click":
      return {
        ...state,
        property1: "variant-2",
      };

    case "click_915":
      return {
        ...state,
        property1: "default",
      };
  }

  return state;
}

SlideMainBanner.propTypes = {
  property1: PropTypes.oneOf(["variant-2", "default"]),
  iconPhone: PropTypes.string,
};
