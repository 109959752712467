import React, {useEffect} from "react";
import {Link} from "react-router-dom";
import {Fs} from "../../components/Fs";
import {FsProduct} from "../../components/FsProduct";
import {Logo} from "../../components/Logo";
import {Product} from "../../components/Product";
import {SlideMainBanner} from "../../components/SlideMainBanner";
import {XemThem} from "../../components/XemThem";
import {Header} from "../../components/Header";
import "./style.css";
import {
  COCONUT,
  FeaturedProducts,
  PINEAPPLE,
  PUMPKIN,
  RICE,
  SEA_FOOD,
  SPECIALTY,
  SPRING_ONION,
  WATERMELON
} from "../../dummy";

export const HomeScreen = () => {
  useEffect(() => {
    window.scrollTo({ top: 0, behavior: "smooth" });
    document.body.style.overflowY = 'auto';
  }, []);
  
  return (
    <div className="home-screen animate-fadeIn">
      <Header />
      <div className="overlap-4">
        <div className="navbar">
          <img className="image-5" alt="Image" src="/img/image-4.png" />
          <p className="h-ng-n-ng-s-n-s">
            <span className="span">
              Hàng nông sản
              <br />
            </span>
            <span className="text-wrapper-14">1000 sản phẩm</span>
          </p>
          <p className="tr-i-c-y-t-i-s">
            <span className="span">
              Trái cây tươi
              <br />
            </span>
            <span className="text-wrapper-14">2500 sản phẩm</span>
          </p>
          <p className="c-s-n-s-n-ph-m">
            <span className="span">
              Đặc sản
              <br />
            </span>
            <span className="text-wrapper-14">5500 sản phẩm</span>
          </p>
          <div className="layer-wrapper">
            <img className="layer" alt="Layer" src="/img/icon/icon_dacsan.png" />
          </div>
          <img className="image-6" alt="Image" src="/img/image-5.png" />
          <div className="text-wrapper-15">. . .</div>
          <div className="text-wrapper-16">. . .</div>
          <div className="text-wrapper-17">. . .</div>
          <div className="text-wrapper-18">. . .</div>
          <p className="thu-h-i-s-n-s-n">
            <span className="span">
              Thuỷ hải sản
              <br />
            </span>
            <span className="text-wrapper-14">2500 sản phẩm</span>
          </p>
          <p className="b-ch-ho-ti-u-d-ng">
            <span className="span">
              Bách hoá tiêu dùng
              <br />
            </span>
            <span className="text-wrapper-14">15500 sản phẩm</span>
          </p>
          <img className="image-7" alt="Image" src="/img/image-6.png" />
          <img className="image-8" alt="Image" src="/img/icon/icon-tieu-dung.png
          " />
        </div>
        <SlideMainBanner
          className="slide-main-banner-instance"
          iconPhone="/img/icon-phone-6.svg"
          iconPhoneClassName="design-component-instance-node"
          property1="default"
        />
      </div>
      <div className="feature-products">
        <div className="c-c-s-n-ph-m-ti-u-bi-wrapper">
          <p className="text-wrapper-19">CÁC SẢN PHẨM TIÊU BIỂU</p>
        </div>
        {
          FeaturedProducts.products.map((item, index) => {
            return (
              <Product
                key={index}
                className={index === 0 ? "product-instance" : `product-${index + 1}`}
                image={item.imgPath}
                property1="default"
                id={item.id}
                text={item.name}
                text1={item.desc}
                text2={item.price}
              />
            );
          })
        }
       {/* <Product
          className="product-instance"
          image="/img/image-coconut.png"
          property1="default"
          text="Gạo Tám Xoan Cần Thơ"
          text1="Hạt gạo Tám Xoan dẹp, trắng và có mùi thơm đặc trưng"
          text2="25.000đ"
        />
        <Product
          className="product-2"
          image="/img/image-7-25.png"
          property1="default"
          text="Bí đỏ hồ lô"
          text1="Bí đỏ hồ lô dẻo, ngon, chất lượng, giàu dinh dưỡng"
          text2="8.000đ"
        />
        <Product
          className="product-3"
          image="/img/image-7-26.png"
          property1="default"
          text="Dưa hấu Long An"
          text1="Vị thuốc giải nhiệt ngày hè. Dưa vỏ xanh mỏng, cuống nhỏ, mọng nước, thanh mát"
          text2="12.000đ"
        />
        <Product
          className="product-4"
          image="/img/image-7-27.png"
          property1="default"
          text="Hành lá"
          text1="Hành lá – loại rau gia vị vô cùng bổ dưỡng"
          text2="10.000đ"
        />
        <Product
          className="product-5"
          image="/img/image-7-28.png"
          property1="default"
          text="Bắp cải trắng"
          text1="Bắp cải là loại rau bổ dưỡng vừa tốt cho sức khỏe, chứa nhiều vitamin C, kali"
          text2="15.000đ"
        />*/}
      </div>
      <div className="hang-nong-san">
        <div className="h-ng-n-ng-s-n-wrapper">
          <div className="text-wrapper-19">HÀNG NÔNG SẢN</div>
        </div>
        <Product
          className="product-instance"
          image="/img/image-coconut.png"
          property1="default"
          text="Dừa Bến Tre"
          text1="Dừa Tươi giàu dinh dưỡng, vừa tốt cho sức khỏe, tươi mát"
          text2="15.000đ"
        />
        <Product
          className="product-2"
          image="/img/image-7-25.png"
          property1="default"
          text="Bí đỏ hồ lô"
          text1="Bí đỏ hồ lô dẻo, ngon, chất lượng, giàu dinh dưỡng"
          text2="8.000đ"
        />
        <Product
          className="product-3"
          image="/img/rice/rice1.png"
          property1="default"
          text="Gạo ST 25"
          text1="Gạo ST25 trở nên đặc biệt là nhờ vào thành phần dinh dưỡng trong từng hạt gạo"
          text2="12.000đ"
        />
        <Product
          className="product-4"
          image="/img/image-7-27.png"
          property1="default"
          text="Hành lá"
          text1="Hành lá – loại rau gia vị vô cùng bổ dưỡng"
          text2="10.000đ"
        />
        <Product
          className="product-5"
          image="/img/image-7-28.png"
          property1="default"
          text="Bắp cải trắng"
          text1="Bắp cải là loại rau bổ dưỡng vừa tốt cho sức khỏe, chứa nhiều vitamin C, kali"
          text2="15.000đ"
        />
        <XemThem className="xem-them-instance"/>
        <div className="overlap-5-nongsan"></div>
      </div>
      <div className="hang-nong-san-2">
        <div className="c-s-n-wrapper">
          <div className="text-wrapper-19">ĐẶC SẢN</div>
        </div>
        {
          SPECIALTY.products.map((item, index) => {
            return (
              <Product
                key={index}
                className={index === 0 ? "product-instance" : `product-${index + 1}`}
                image={item.imgPath}
                property1="default"
                id={item.id}
                text={item.name}
                text1={item.desc}
                text2={item.price}
              />
            );
          })
        }
        <XemThem className="xem-them-2"/>
        <div className="overlap-5-dacsan"></div>
      </div>
      <div className="trai-cay-tuoi">
        <div className="tr-i-c-y-t-i-wrapper">
          <div className="text-wrapper-19">TRÁI CÂY TƯƠI</div>
        </div>
        <Product
          className="product-6"
          image="/img/image-coconut.png"
          property1="default"
          text="Dừa Bến Tre"
          text1="Dừa Tươi giàu dinh dưỡng, vừa tốt cho sức khỏe, tươi mát"
          text2="15.000đ"
        />
        <Product
          className="product-2"
          image="/img/image-7-25.png"
          property1="default"
          text="Bí đỏ hồ lô"
          text1="Bí đỏ hồ lô dẻo, ngon, chất lượng, giàu dinh dưỡng"
          text2="8.000đ"
        />
        <Product
          className="product-3"
          image="/img/image-7-26.png"
          property1="default"
          text="Dưa hấu Long An"
          text1="Vị thuốc giải nhiệt ngày hè. Dưa vỏ xanh mỏng, cuống nhỏ, mọng nước, thanh mát"
          text2="12.000đ"
        />
        <Product
          className="product-4"
          image="/img/image-7-27.png"
          property1="default"
          text="Hành lá"
          text1="Hành lá – loại rau gia vị vô cùng bổ dưỡng"
          text2="10.000đ"
        />
        <XemThem className="xem-them-3" />
        <div className="overlap-5">
          <div className="text-wrapper-20">50%</div>
          <div className="text-wrapper-21">Giảm đến</div>
        </div>
      </div>
      <div className="trai-cay-tuoi-2">
        <div className="thu-h-i-s-n-wrapper">
          <div className="text-wrapper-19">{SEA_FOOD.name}</div>
        </div>
        {
          SEA_FOOD.products.map((item, index) => {
            return (
              <Product
                key={index}
                className={index === 0 ? "product-instance" : `product-${index + 1}`}
                image={item.imgPath}
                property1="default"
                text={item.name}
                text1={item.desc}
                text2={item.price}
              />
            );
          })
        }
        <XemThem className="xem-them-2"/>
        <div className="overlap-5-thuysan"></div>
      </div>
      <div className="flash-sale">
      <div className="overlap-6">
          <img className="mask-group" alt="Mask group" src="/img/mask-group-2.png" />
          <Fs className="fs-1" property1="default" data={PINEAPPLE} />
          <FsProduct
            className="fs-product-instance"
            property1="default"
            id={PUMPKIN.id}
            text={PUMPKIN.name}
            text1={PUMPKIN.desc}
            image={PUMPKIN.imgPath}
          />
          <FsProduct
            className="fs-product-2"
            property1="default"
            id={RICE.id}
            text={RICE.name}
            text1={RICE.desc}
            image={RICE.imgPath}
          />
          <img className="vector-2" alt="Vector" src="/img/vector-24.svg" />
          <div className="u-i-ch-p-nho-ng">ƯU ĐÃI CHỚP NHOÁNG</div>
        </div>
        <FsProduct
          className="fs-product-3"
          property1="default"
          id={WATERMELON.id}
          text={WATERMELON.name}
          text1={WATERMELON.desc}
          image={WATERMELON.imgPath}
        />
        <FsProduct
          className="fs-product-4"
          property1="default"
          id={SPRING_ONION.id}
          text={SPRING_ONION.name}
          text1={SPRING_ONION.desc}
          image={SPRING_ONION.imgPath}
        />
        <div className="overlap-7">
          <div className="element-2">
            19&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;:&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;05&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;:&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;48
          </div>
          <div className="gi-ph-t-gi-y">
            giờ&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            phút&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;giây
          </div>
        </div>
      </div>
      <div className="top-seller">
        <div className="overlap-8">
          <div className="top-ncc">
            <div className="group-5">
              <p className="text-wrapper-22">Nông sản cô Ba Miền Tây</p>
              <div className="overlap-group-9">
                <p className="chuy-n-cung-c-p-h-ng">
                  Chuyên cung cấp hàng nông sản chất lượng cao, xuất khẩu sang các nước Châu Âu và Châu Phi
                </p>
                <div className="rate-3">
                  <img className="star-16" alt="Star" src="/img/star-2-39.svg" />
                  <img className="star-17" alt="Star" src="/img/star-3-39.svg" />
                  <img className="star-18" alt="Star" src="/img/star-4-39.svg" />
                  <img className="star-19" alt="Star" src="/img/star-5-1.svg" />
                  <img className="star-20" alt="Star" src="/img/star-6-39.svg" />
                </div>
              </div>
            </div>
            <img className="mask-group-2" alt="Mask group" src="/img/mask-group-1.png" />
          </div>
          <div className="nh-cung-c-p-ti-u-bi-wrapper">
            <p className="text-wrapper-19">NHÀ CUNG CẤP TIÊU BIỂU</p>
          </div>
          <img className="vector-3" alt="Vector" src="/img/vector-25.svg" />
        </div>
        <div className="top-ncc-2">
          <div className="group-5">
            <p className="text-wrapper-22">Gạo chú Tám Cần Thơ</p>
            <div className="overlap-group-9">
              <p className="chuy-n-cung-c-p-h-ng">
                Chuyên cung cấp gạo Nàng Thơm, gạo sạch chất lượng cao, được nhiều người tin dùng
              </p>
              <div className="rate-3">
                <img className="star-16" alt="Star" src="/img/star-2-39.svg" />
                <img className="star-17" alt="Star" src="/img/star-3-39.svg" />
                <img className="star-18" alt="Star" src="/img/star-4-39.svg" />
                <img className="star-19" alt="Star" src="/img/star-5-1.svg" />
                <img className="star-20" alt="Star" src="/img/star-6-39.svg" />
              </div>
            </div>
          </div>
          <img className="mask-group-2" alt="Mask group" src="/img/mask-group-4.png" />
        </div>
        <div className="top-ncc-3">
          <div className="group-5">
            <div className="text-wrapper-22">Rau sạch 3SS</div>
            <div className="overlap-group-9">
              <p className="chuy-n-cung-c-p-h-ng">
                Rau tươi đảm bảo an toàn vệ sinh thực phẩm bao gồm tất cả các loại rau ăn củ, thân, lá, hoa quả.
              </p>
              <div className="rate-3">
                <img className="star-16" alt="Star" src="/img/star-2-39.svg" />
                <img className="star-17" alt="Star" src="/img/star-3-39.svg" />
                <img className="star-18" alt="Star" src="/img/star-4-39.svg" />
                <img className="star-19" alt="Star" src="/img/star-5-1.svg" />
                <img className="star-20" alt="Star" src="/img/star-6-39.svg" />
              </div>
            </div>
          </div>
          <img className="mask-group-2" alt="Mask group" src="/img/mask-group-5.png" />
        </div>
        <img className="vector-4" alt="Vector" src="/img/vector-26.svg" />
      </div>
      <div className="footer">
        <div className="USP">
          <img className="layer-2" alt="Layer" src="/img/layer-3-8.svg" />
          <div className="giao-h-ng-mi-n-ph">GIAO HÀNG NHANH CHÓNG, TIẾT KIỆM</div>
          <p className="ch-nh-s-ch-i-tr-linh">CHÍNH SÁCH ĐỔI TRẢ LINH HOẠT</p>
          <div className="h-tr">HỖ TRỢ 24/7</div>
          <p className="text-wrapper-29">Hỗ trợ bà con nông dân giao hàng nhanh chóng khắp cả nước.</p>
          <p className="text-wrapper-30">Giúp bà con nông dân và người tiêu dùng đổi trả thuận tiện hơn</p>
          <p className="text-wrapper-31">Đội ngũ hỗ trợ bà con 24/7 nhanh chóng, tức thời</p>
          <img className="layer-3" alt="Layer" src="/img/icon/flex_change.png" />
          <img className="layer-4" alt="Layer" src="/img/icon/support.png" />
        </div>
        <div className="footer-2">
          <Link className="overlap-group-wrapper" to="/home">
            <div className="overlap-group-12">
              <Logo className="logo-3" divClassName="logo-2" divClassNameOverride="logo-2" />
              <img className="grocery-cart-white" alt="Grocery cart white" src="/img/grocery-cart-white-1.png" />
            </div>
          </Link>
          <div className="overlap-9">
            <p className="text-wrapper-32">
              Funong.vn là cầu nối giúp bà con nông dân có thể đưa sản phẩm của mình tới tay người tiêu dùng một cách dễ
              dàng và thuận tiện. Bà con nông dân đỡ được nỗi lo về chi phí vận chuyển cũng như chi phí bán hàng mà vẫn
              đạt được hiệu quả cao nhất
            </p>
            <p className="text-wrapper-33">223 Trần Hưng Đạo, Quận 1, TPHCM</p>
          </div>
          <img className="layer-5" alt="Layer" src="/img/icon/location.png" />
          <img className="layer-6" alt="Layer" src="/img/icon/phone.png" />
          <div className="text-wrapper-34">1900 6067</div>
          <div className="flexcontainer">
            <p className="text-i">
              <span className="text-wrapper-35">HỖ TRỢ KHÁCH HÀNG</span>
              <span className="text-wrapper-36">
                &nbsp;&nbsp; <br />
              </span>
            </p>
            <p className="text-i">
              <span className="text-wrapper-36">
                Chính sách giao hàng&nbsp;&nbsp;&nbsp;&nbsp; <br />
              </span>
            </p>
            <p className="text-i">
              <span className="text-wrapper-36">
                Chính sách đổi trả&nbsp;&nbsp;&nbsp;&nbsp; <br />
              </span>
            </p>
            <p className="text-i">
              <span className="text-wrapper-36">
                Chính sách thành viên&nbsp;&nbsp;&nbsp;&nbsp;
                <br />
              </span>
            </p>
            <p className="text-i">
              <span className="text-wrapper-36">
                Hướng dẫn mua hàng và hoàn tiền&nbsp;&nbsp;&nbsp;&nbsp; <br />
              </span>
            </p>
            <p className="text-i">
              <span className="text-wrapper-36">
                Chính sách xuất hóa đơn đỏ&nbsp;&nbsp;&nbsp;&nbsp; <br />
              </span>
            </p>
            <p className="text-i">
              <span className="text-wrapper-36">Giấy chứng nhận Vệ sinh ATTP</span>
            </p>
          </div>
          <div className="i-t-c-v-n-chuy-n">ĐỐI TÁC VẬN CHUYỂN</div>
          <div className="i-t-c-thanh-to-n">ĐỐI TÁC THANH TOÁN</div>
          <div className="group-6">
            <div className="goo-pay">GOOPAY</div>
            <img className="layer-7" alt="Layer" src="/img/icon/partner_gp.png" />
          </div>
          <div className="flexcontainer-i">
            <p className="text-i">
              <span className="text-wrapper-36">
                <br />
              </span>
            </p>
            <p className="text-i">
              <span className="text-wrapper-36">
                <br />
              </span>
            </p>
            <p className="text-i">
              <span className="text-wrapper-36">
                Hướng dẫn mua hàng <br />
              </span>
            </p>
            <p className="text-i">
              <span className="text-wrapper-36">
                Hóa đơn điện tử <br />
              </span>
            </p>
            <p className="text-i">
              <span className="text-wrapper-36">
                Chính sách khách hàng <br />
              </span>
            </p>
            <p className="text-i">
              <span className="text-wrapper-36">
                Tích điểm <br />
              </span>
            </p>
            <p className="text-i">
              <span className="text-wrapper-36">Cảnh báo giả mạo</span>
            </p>
          </div>
          <div className="img-wrapper">
            <img className="image-10" alt="Image" src="/img/image-13.png" />
          </div>
          <img className="image-11" alt="Image" src="/img/image-12.png" />
        </div>
      </div>
    </div>
  );
};
