/*
We're constantly improving the code you see. 
Please share your feedback here: https://form.asana.com/?k=uvp-HPgd3_hyoXRBw1IcNg&d=1152665201300829
*/

import React from "react";

export const IconUser = ({ className }) => {
  return (
    <svg
      className={`icon-user-1 ${className}`}
      fill="none"
      height="24"
      viewBox="0 0 18 24"
      width="18"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g className="g" clipPath="url(#clip0_27_131)">
        <path
          className="path"
          d="M8.73018 10.76C6.08017 10.76 3.93018 8.35 3.93018 5.38C3.93018 2.41 6.08017 0 8.73018 0C11.3802 0 13.5302 2.41 13.5302 5.38C13.5302 8.35 11.3802 10.76 8.73018 10.76ZM8.73018 2C7.19018 2 5.93018 3.52 5.93018 5.38C5.93018 7.24 7.19018 8.76 8.73018 8.76C10.2702 8.76 11.5302 7.24 11.5302 5.38C11.5302 3.52 10.2702 2 8.73018 2Z"
          fill="#9E9E9E"
        />
        <path
          className="path"
          d="M15.9601 23.14H1.3301C0.800099 23.14 0.370099 22.73 0.330099 22.21L9.9286e-05 17.49C-0.119901 15.75 1.2301 13.75 2.9501 13.12C6.6201 11.8 10.6601 11.81 14.3201 13.12C16.0501 13.76 17.4001 15.76 17.2701 17.5L16.9501 22.22C16.9101 22.75 16.4801 23.15 15.9501 23.15L15.9601 23.14ZM2.2601 21.14H15.0201L15.2801 17.35C15.3301 16.57 14.6201 15.35 13.6401 14.99C10.4401 13.84 6.8601 13.84 3.6401 14.99C2.6801 15.35 1.9501 16.57 2.0101 17.35L2.2701 21.14H2.2601Z"
          fill="#9E9E9E"
        />
      </g>
      <defs className="defs">
        <clipPath className="clip-path" id="clip0_27_131">
          <rect className="rect" fill="white" height="23.14" width="17.28" />
        </clipPath>
      </defs>
    </svg>
  );
};
