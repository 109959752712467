/*
We're constantly improving the code you see. 
Please share your feedback here: https://form.asana.com/?k=uvp-HPgd3_hyoXRBw1IcNg&d=1152665201300829
*/

import PropTypes from "prop-types";
import React from "react";
import { useReducer } from "react";
import "./style.css";

export const LienHe = ({ property1, className }) => {
  const [state, dispatch] = useReducer(reducer, {
    property1: property1 || "default",
  });

  return (
    <div
      className={`lien-he property-1-10-${state.property1} ${className}`}
      onMouseLeave={() => {
        dispatch("mouse_leave");
      }}
      onMouseEnter={() => {
        dispatch("mouse_enter");
      }}
    >
      {state.property1 === "default" && (
        <>
          <div className="text-wrapper-13">Liên hệ</div>
          <img className="vector" alt="Vector" src="/img/vector-11.svg" />
        </>
      )}

      {state.property1 === "variant-2" && (
        <div className="overlap-group-8">
          <div className="rectangle-7" />
          <div className="rectangle-8" />
          <div className="text-wrapper-13">Liên hệ</div>
          <img className="vector" alt="Vector" src="/img/vector-11.svg" />
        </div>
      )}
    </div>
  );
};

function reducer(state, action) {
  switch (action) {
    case "mouse_enter":
      return {
        ...state,
        property1: "variant-2",
      };

    case "mouse_leave":
      return {
        ...state,
        property1: "default",
      };
  }

  return state;
}

LienHe.propTypes = {
  property1: PropTypes.oneOf(["variant-2", "default"]),
};
