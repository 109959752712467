import React from "react";
import "./style.css";
import {Link, useNavigate} from "react-router-dom";

export const Checkout = () => {
  const navigate= useNavigate()
  const gotoSuccess = () => {
    navigate("/success")
  }
  
  return (
    <div className="checkout">
      <div className="site-heading">
        <div className="heading">
          <div className="overlap-group">
            <button className="btn">
              <button className="button">Tìm kiếm</button>
            </button>
          </div>
          <img className="icon-gio-hang" src="/img/icon-gio-hang-1.svg"/>
          <img className="icon-love" src="/img/icon-love-1.svg"/>
          <img className="icon-user" src="/img/icon-user-1.svg"/>
          <div className="logo">
            <img className="img" src="/img/grocery-cart-1-2.png"/>
            <div className="text-wrapper">Funong</div>
            <div className="div">.vn</div>
          </div>
        </div>
        <div className="topheading">
          <div className="p marquee">
            <span>Phiên bản thử nghiệm</span>
          </div>
          <p className="hotline"><span className="span">Hotline: </span> <span
            className="text-wrapper-2">1900 6067</span></p>
          <img className="icon-phone" src="/img/icon-phone-1.svg"/>
        </div>
        <div className="main-nav">
          <img className="icon-gift" src="/img/icon-gift-1.svg"/>
          <div className="text-wrapper-3">Khuyến mãi HOT</div>
          <Link to={'/'}>
            <div className="home">
              <img className="icon-home" src="/img/icon-home-2.svg"/>
              <div className="text-wrapper-4">Trang chủ</div>
            </div>
          </Link>
          
          <Link to={'/cua-hang'}>
            <div className="sanpham">
              <div className="text-wrapper-4">Sản phẩm</div>
              <img className="icon-sanpham" src="/img/icon-sanpham-3.svg"/>
            </div>
          </Link>
          <Link to={'/nha-cung-cap'}>
            <div className="nha-cung-cap">
              <div className="text-wrapper-4">Nhà cung cấp</div>
              <div className="icon-NCC">
                <div className="overlap-group-3">
                  <img className="vector-6" alt="Vector" src="/img/vector-16.svg"/>
                  <img className="vector-7" alt="Vector" src="/img/vector-13.svg"/>
                  <img className="vector-8" alt="Vector" src="/img/vector-34.svg"/>
                  <img className="vector-9" alt="Vector" src="/img/vector-35.svg"/>
                </div>
              </div>
            </div>
          </Link>
          <div className="lien-he">
            <div className="text-wrapper-4">Liên hệ</div>
            <img className="vector-10" src="/img/vector-11.svg"/>
          </div>
          <Link to={'/lien-he-futaex'}>
            <div className="contact-futaex">
              <div className="overlap"><img className="image" src="/img/image-13-1.png"/></div>
              <div className="text-wrapper-5">Liên hệ với</div>
              <img className="polygon" src="/img/polygon-4.svg"/>
            </div>
          </Link>
        
        </div>
      </div>
      <div className="overlap-2">
        <div className="footer">
          <div className="USP">
            <img className="layer" src="/img/layer-3-8.svg"/>
            <div className="giao-h-ng-mi-n-ph">GIAO HÀNG NHANH CHÓNG, TIẾT KIỆM</div>
            <p className="ch-nh-s-ch-i-tr-linh">CHÍNH SÁCH ĐỔI TRẢ LINH HOẠT</p>
            <div className="h-tr">HỖ TRỢ 24/7</div>
            <p className="text-wrapper-6">Hỗ trợ bà con nông dân giao hàng nhanh chóng khắp cả nước.</p>
            <p className="text-wrapper-7">Giúp bà con nông dân và người tiêu dùng đổi trả thuận tiện hơn</p>
            <p className="text-wrapper-8">Đội ngũ hỗ trợ bà con 24/7 nhanh chóng, tức thời</p>
            <img className="layer-2" src="/img/icon/flex_change.png"/>
            <img className="layer-3" src="/img/icon/support.png"/>
          </div>
          <div className="footer-2">
            <div className="group">
              <div className="overlap-group-4">
                <div className="logo-2">
                  <img className="img" src="/img/grocery-cart-1-2.png"/>
                  <div className="text-wrapper-9">Funong</div>
                  <div className="text-wrapper-10">.vn</div>
                </div>
                <img className="img" src="/img/grocery-cart-white-1.png"/>
              </div>
            </div>
            <div className="overlap-3">
              <p className="text-wrapper-11">
                Funong.vn là cầu nối giúp bà con nông dân có thể đưa sản phẩm của mình tới tay người tiêu dùng một cách
                dễ dàng và thuận tiện. Bà con nông dân đỡ được nỗi lo về chi phí vận chuyển cũng như chi phí bán hàng mà
                vẫn đạt được hiệu quả cao nhất
              </p>
              <p className="text-wrapper-12">223 Trần Hưng Đạo, Quận 1, TPHCM</p>
            </div>
            <img className="layer-4" src="/img/icon/location.png"/>
            <img className="layer-5" src="/img/icon/phone.png"/>
            <div className="text-wrapper-13">1900 6067</div>
            <div className="flexcontainer">
              <p className="text-i">
                <span className="text-wrapper-14">HỖ TRỢ KHÁCH HÀNG</span>
                <span className="text-wrapper-15">&nbsp;&nbsp; <br/></span>
              </p>
              <p className="text-i">
                <span className="text-wrapper-15">Chính sách giao hàng&nbsp;&nbsp;&nbsp;&nbsp; <br/></span>
              </p>
              <p className="text-i">
                <span className="text-wrapper-15">Chính sách đổi trả&nbsp;&nbsp;&nbsp;&nbsp; <br/></span>
              </p>
              <p className="text-i">
                <span className="text-wrapper-15">Chính sách thành viên&nbsp;&nbsp;&nbsp;&nbsp;<br/></span>
              </p>
              <p className="text-i">
                <span className="text-wrapper-15">Hướng dẫn mua hàng và hoàn tiền&nbsp;&nbsp;&nbsp;&nbsp; <br/></span>
              </p>
              <p className="text-i">
                <span className="text-wrapper-15">Chính sách xuất hóa đơn đỏ&nbsp;&nbsp;&nbsp;&nbsp; <br/></span>
              </p>
              <p className="text-i"><span className="text-wrapper-15">Giấy chứng nhận Vệ sinh ATTP</span></p>
            </div>
            <div className="i-t-c-v-n-chuy-n">ĐỐI TÁC VẬN CHUYỂN</div>
            <div className="i-t-c-thanh-to-n">ĐỐI TÁC THANH TOÁN</div>
            <div className="group-2">
              <div className="goo-pay">GOOPAY</div>
              <img className="layer-6" src="/img/icon/partner_gp.png"/>
            </div>
            <div className="flexcontainer-i">
              <p className="text-i">
                <span className="text-wrapper-15"><br/></span>
              </p>
              <p className="text-i">
                <span className="text-wrapper-15"><br/></span>
              </p>
              <p className="text-i">
                <span className="text-wrapper-15">Hướng dẫn mua hàng <br/></span>
              </p>
              <p className="text-i">
                <span className="text-wrapper-15">Hóa đơn điện tử <br/></span>
              </p>
              <p className="text-i">
                <span className="text-wrapper-15">Chính sách khách hàng <br/></span>
              </p>
              <p className="text-i">
                <span className="text-wrapper-15">Tích điểm <br/></span>
              </p>
              <p className="text-i"><span className="text-wrapper-15">Cảnh báo giả mạo</span></p>
            </div>
            <div className="image-wrapper"><img className="image-2" src="/img/image-13.png"/></div>
            <img className="image-3" src="/img/image-12.png"/>
          </div>
        </div>
        <div className="frame">
          <div className="sp-wrapper">
            <div className="sp">
              <img className="rectangle-4" src="/img/rectangle-18.png"/>
              <p className="text-wrapper-16">Gạo ST 25 Sóc Trăng</p>
              <div className="text-wrapper-17">Số lượng: 3</div>
              <div className="text-wrapper-18">800.000 VND</div>
              <img className="layer-8" src="/img/icon/trash.png"/>
              <div className="layer-7">
                <div className="li-n-h-wrapper">
                  <div className="li-n-h">Thêm sản phẩm</div>
                </div>
              </div>
              <div className="soluong">
                <div className="group-4">
                  <div className="text-wrapper-19">-</div>
                  <div className="text-wrapper-20">+</div>
                  <div className="text-wrapper-21">Số lượng</div>
                  <div className="div-wrapper">
                    <div className="text-wrapper-22">3</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="sp-2">
          <div className="group-5"></div>
            <p className="text-wrapper-16">Gạo Tám Xoan Cần Thơ</p>
            <div className="text-wrapper-17">Số lượng: 5</div>
            <div className="text-wrapper-23">1.200.000 VND</div>
            <img className="layer-8" src="/img/icon/trash.png"/>
            <div className="soluong">
              <div className="group-4">
                <div className="text-wrapper-19">-</div>
                <div className="text-wrapper-20">+</div>
                <div className="text-wrapper-21">Số lượng</div>
                <div className="div-wrapper">
                  <div className="text-wrapper-22">5</div>
                </div>
              </div>
            </div>
          </div>
          <div className="text-wrapper-24">Giỏ hàng</div>
          <div className="frame-2">
            <div className="text-wrapper-25">Thông tin đơn hàng</div>
            <div className="flexcontainer-2">
              <p className="text">
                Tạm tính (2 sản phẩm)
              </p>
              <p className="text">
                Khuyến mãi
              </p>
              <p className="text">Phí vận chuyển</p>
            </div>
            <div className="flexcontainer-3">
              <p className="span-wrapper">
                2.000.000VND
              </p>
              <p className="span-wrapper">
                0 VND
              </p>
              <p className="span-wrapper">0 VND</p>
            </div>
            <div className="overlap-4">
              <button className="button-wrapper">
                <button className="button">Áp dụng</button>
              </button>
              <p className="text-wrapper-27">Nhập mã khuyến mãi của bạn</p>
            </div>
            <div className="text-wrapper-28">Tổng cộng</div>
            <div className="text-wrapper-29">1.200.000 VND</div>
            <div className="frame-3">
              <div className="text-wrapper-30">Thông tin thanh toán</div>
              <img className="layer-9" src="/img/icon/icon_cod.png"/>
              <p className="text-wrapper-31">Thanh toán khi nhận hàng</p>
              <p className="text-wrapper-32">Thanh toán bằng thẻ ATM</p>
              <p className="text-wrapper-33">Thanh toán bằng thẻ tín dụng</p>
              <p className="v-goopay-s-d">
                <span className="text-wrapper-34">Ví GooPay </span>
                <span className="text-wrapper-35">(số dư: 500.000 VND)</span>
              </p>
              <div className="ATM">
                <div className="overlap-group-6">
                  <img className="vector-15" src="/img/icon/icon_atm.png"/>
                  <div className="text-wrapper-36">ATM</div>
                </div>
              </div>
              <img className="group-6" src="/img/icon/icon_credit.png"/>
              <div className="overlap-5">
                <div className="text-wrapper-37">G</div>
              </div>
              <div className="ellipse-wrapper">
                <div className="ellipse"></div>
              </div>
              <div className="ellipse-2"></div>
              <div className="ellipse-3"></div>
              <div className="ellipse-4"></div>
            </div>
            <div className="group-7" onClick={gotoSuccess}>
              <div className="li-n-h">Đặt hàng</div>
            </div>
          </div>
          <div className="overlap-6">
            <div className="thong-tin-nhan-hang">
              <div className="overlap-7">
                <div className="overlap-8">
                  <div className="rectangle-wrapper">
                    <div className="rectangle-5"></div>
                  </div>
                  <div className="group-8">
                    <div className="rectangle-6"></div>
                  </div>
                  <p className="text-wrapper-38">Vui lòng nhập họ và tên người nhận</p>
                  <p className="text-wrapper-39">Nhập địa chỉ người nhận</p>
                </div>
                <div className="overlap-9">
                  <div className="group-9">
                    <div className="rectangle-7"></div>
                  </div>
                  <div className="group-10">
                    <div className="rectangle-7"></div>
                  </div>
                  <div className="group-11">
                    <div className="rectangle-5"></div>
                  </div>
                  <div className="text-wrapper-40">Tỉnh/ Thành phố</div>
                  <div className="text-wrapper-41">Quận/ Huyện</div>
                  <div className="text-wrapper-42">Tỉnh/ Thành...</div>
                  <div className="text-wrapper-43">Quận/ Huyện...</div>
                  <img className="vector-16" src="/img/vector-1.svg"/>
                  <p className="text-wrapper-44">Vui lòng nhập số điện thoại người nhận</p>
                  <div className="group-12">
                    <div className="li-n-h">Lưu địa chỉ</div>
                  </div>
                </div>
                <div className="overlap-10">
                  <div className="group-13">
                    <div className="rectangle-7"></div>
                  </div>
                  <div className="text-wrapper-45">Phường/ Xã...</div>
                </div>
                <div className="text-wrapper-46">Địa chỉ</div>
                <img className="vector-17" src="/img/vector-1.svg"/>
                <img className="vector-18" src="/img/vector-1.svg"/>
              </div>
              <p className="h-v-t-n">
                <span className="text-wrapper-34">Họ và Tên </span> <span className="text-wrapper-47">*</span>
              </p>
              <p className="i-n-tho-i">
                <span className="text-wrapper-34">Điện thoại </span> <span className="text-wrapper-47">*</span>
              </p>
            </div>
            <div className="frame-4">
              <p className="text-wrapper-48">Thông tin người nhận hàng</p>
              <img className="group-14" src="/img/group-17.png"/>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
