import React, {useEffect} from "react";
import "./style.css";
import {Link} from "react-router-dom";
import {PROVIDER} from "../../../utils/constants";


export const Home = () => {
  
  return (
    <div className="content animate-fadeIn">
      <div className="frame">
        <div className="group">
          <div className="div">
            <img className="layer" src="/img/provider/layer-3-15.svg"/>
            <div className="text-wrapper">Chờ lấy hàng</div>
            <div className="text-wrapper-2">100</div>
          </div>
          <div className="frame-2">
            <img className="img" src="/img/provider/layer-3-16.svg"/>
            <div className="text-wrapper-3">Đang giao hàng</div>
            <div className="text-wrapper-4">80</div>
          </div>
          <div className="frame-3">
            <img className="layer-2" src="/img/provider/layer-3-17.svg"/>
            <div className="text-wrapper-5">Giao thành công</div>
            <div className="text-wrapper-4">90</div>
          </div>
          <div className="frame-4">
            <img className="layer-3" src="/img/provider/layer-3-18.svg"/>
            <div className="text-wrapper-6">Hoàn hàng</div>
            <div className="text-wrapper-7">23</div>
          </div>
        </div>
        <div className="text-wrapper-8">Đơn hàng</div>
        <div className="overlap-group">
          <div className="t-h-m-nay">Từ 01/07/2024&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Hôm nay</div>
          <img className="polygon" src="/img/provider/polygon-7.svg"/>
        </div>
        <img className="polygon-2" src="/img/provider/polygon-6.svg"/>
        <Link to={`/nha-cung-cap/${PROVIDER.MANAGE_ORDER.slug}`}>
          <button className="btn">
            <button className="button">Xem chi tiết</button>
          </button>
        </Link>
      
      </div>
      <div className="frame-5">
        <button className="button-wrapper">
        <button className="button">Xem chi tiết</button>
        </button>
        <div className="group-2">
          <div className="frame-6">
            <div className="text-wrapper-9">Tổng doanh thu</div>
            <div className="text-wrapper-10">100</div>
            <img className="layer-4" src="/img/provider/layer-3-19.svg"/>
          </div>
          <div className="frame-7">
            <img className="layer-5" src="/img/provider/layer-3-20.svg"/>
            <div className="text-wrapper-11">Tiền đã thanh toán</div>
            <div className="text-wrapper-12">80</div>
          </div>
          <div className="frame-8">
            <img className="layer-6" src="/img/provider/layer-3-21.svg"/>
            <div className="text-wrapper-13">Chưa thanh toán</div>
            <div className="text-wrapper-12">120</div>
          </div>
        </div>
        <div className="text-wrapper-14">Tài chính</div>
        <div className="overlap">
          <div className="t-h-m-nay">Từ 01/07/2024&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Hôm nay</div>
          <img className="polygon" src="/img/provider/polygon-7.svg"/>
        </div>
        <img className="polygon-3" src="/img/provider/polygon-6.svg"/>
      </div>
      <div className="frame-9">
        <div className="text-wrapper-15">Bản tin</div>
        <div className="news">
          <div className="text-wrapper-16">Ngày đăng: 05/07/2024</div>
          <div className="text-wrapper-17">Nguồn: nongnghiep.vn</div>
          <p className="p">Câu chuyện kết nối - Khoa học gặp gỡ cuộc sống</p>
          <img className="line" src="/img/provider/line-7-2.svg"/>
          <p className="k-t-n-i-s-n-ph-m-l-m">
            &#39;Kết nối sản phẩm&#39; là một cách nói khác của thị trường khoa học - công&nbsp;&nbsp;nghệ (KHCN). Thị
            trường được xem như một cái chợ, nơi &#39;cung&#39; hàng hóa&nbsp;&nbsp;gặp &#39;cầu&#39; hàng hóa, nơi
            người bán giao lưu với người mua.
          </p>
          <img className="image" src="/img/provider/image-13-2.png"/>
        </div>
        <div className="news-2">
          <div className="text-wrapper-18">Ngày đăng: 05/07/2024</div>
          <div className="text-wrapper-19">Nguồn: nongnghiep.vn</div>
          <p className="text-wrapper-20">&#39;Cánh tay nối dài&#39; thực chất trong chuỗi sản xuất</p>
          <img className="line-2" src="/img/provider/line-7-2.svg"/>
          <p className="c-c-t-khuy-n-n-ng-c">
            Các&nbsp;&nbsp;tổ khuyến nông cộng đồng trên địa bàn tỉnh Hòa Bình đang trở thành điểm&nbsp;&nbsp;tựa vững
            chắc dẫn dắt nông dân tổ chức lại sản xuất, phát triển liên&nbsp;&nbsp;kết với doanh nghiệp.
          </p>
          <img className="image-2" src="/img/provider/image-14-3.png"/>
          <img className="line-3" src="/img/provider/line-5-1.svg"/>
        </div>
        <div className="news-3">
          <div className="text-wrapper-18">Ngày đăng: 05/07/2024</div>
          <div className="text-wrapper-19">Nguồn: nongnghiep.vn</div>
          <p className="text-wrapper-20">&#39;Cánh tay nối dài&#39; thực chất trong chuỗi sản xuất</p>
          <img className="line-2" src="/img/provider/line-7-2.svg"/>
          <p className="c-c-t-khuy-n-n-ng-c">
            Các&nbsp;&nbsp;tổ khuyến nông cộng đồng trên địa bàn tỉnh Hòa Bình đang trở thành điểm&nbsp;&nbsp;tựa vững
            chắc dẫn dắt nông dân tổ chức lại sản xuất, phát triển liên&nbsp;&nbsp;kết với doanh nghiệp.
          </p>
          <img className="image-2" src="/img/provider/image-14-3.png"/>
          <img className="line-3" src="/img/provider/line-5-1.svg"/>
        </div>
        <div className="news-4">
          <div className="text-wrapper-21">Ngày đăng: 05/07/2024</div>
          <div className="text-wrapper-22">Nguồn: nongnghiep.vn</div>
          <p className="text-wrapper-20">
            Công nghệ thực phẩm - Tốp ngành &#39;hot&#39;, dẫn đầu về <br/>nhu cầu nhân lực
          </p>
          <img className="line-4" src="/img/provider/line-7-4.svg"/>
          <p className="c-ng-ngh-th-c-ph-m-l">
            Công nghệ thực phẩm là ngành được xếp thứ hai trong ba nhóm ngành dẫn&nbsp;&nbsp;đầu về nhu cầu nhân lực
            giai đoạn 2015 - 2025...
          </p>
          <img className="image-3" src="/img/provider/image-15-1.png"/>
          <img className="line-5" src="/img/provider/line-5-3.svg"/>
        </div>
        <div className="news-5">
          <div className="text-wrapper-21">Ngày đăng: 05/07/2024</div>
          <div className="text-wrapper-22">Nguồn: nongnghiep.vn</div>
          <p className="text-wrapper-20">
            Công nghệ thực phẩm - Tốp ngành &#39;hot&#39;, dẫn đầu về <br/>nhu cầu nhân lực
          </p>
          <img className="line-4" src="/img/provider/line-7-4.svg"/>
          <p className="c-ng-ngh-th-c-ph-m-l">
            Công nghệ thực phẩm là ngành được xếp thứ hai trong ba nhóm ngành dẫn&nbsp;&nbsp;đầu về nhu cầu nhân lực
            giai đoạn 2015 - 2025...
          </p>
          <img className="image-3" src="/img/provider/image-15-1.png"/>
          <img className="line-5" src="/img/provider/line-5-3.svg"/>
        </div>
        <div className="news-6">
          <div className="text-wrapper-18">Ngày đăng: 05/07/2024</div>
          <div className="text-wrapper-19">Nguồn: nongnghiep.vn</div>
          <p className="text-wrapper-20">&#39;Cánh tay nối dài&#39; thực chất trong chuỗi sản xuất</p>
          <img className="line-2" src="/img/provider/line-7-6.png"/>
          <p className="c-c-t-khuy-n-n-ng-c">
            Các&nbsp;&nbsp;tổ khuyến nông cộng đồng trên địa bàn tỉnh Hòa Bình đang trở thành điểm&nbsp;&nbsp;tựa vững
            chắc dẫn dắt nông dân tổ chức lại sản xuất, phát triển liên&nbsp;&nbsp;kết với doanh nghiệp.
          </p>
          <img className="image-2" src="/img/provider/image-14-3.png"/>
          <img className="line-3" src="/img/provider/line-5-5.png"/>
        </div>
        <div className="news-7">
          <div className="text-wrapper-18">Ngày đăng: 05/07/2024</div>
          <div className="text-wrapper-19">Nguồn: nongnghiep.vn</div>
          <p className="text-wrapper-20">&#39;Cánh tay nối dài&#39; thực chất trong chuỗi sản xuất</p>
          <img className="line-2" src="/img/provider/line-7-6.png"/>
          <p className="c-c-t-khuy-n-n-ng-c">
            Các&nbsp;&nbsp;tổ khuyến nông cộng đồng trên địa bàn tỉnh Hòa Bình đang trở thành điểm&nbsp;&nbsp;tựa vững
            chắc dẫn dắt nông dân tổ chức lại sản xuất, phát triển liên&nbsp;&nbsp;kết với doanh nghiệp.
          </p>
          <img className="image-2" src="/img/provider/image-14-3.png"/>
          <img className="line-3" src="/img/provider/line-5-5.png"/>
        </div>
      </div>
    </div>
  );
};
