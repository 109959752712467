import React, {useEffect} from "react";
import "./style.css";
import {useNavigate} from "react-router-dom";

export const ChoosePrice = ({handlePopup}) => {
  const navigate = useNavigate();
  const [muaLe, setMuaLe] = React.useState(true);
  useEffect(() => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  }, []);
  
  const clickMuale = () => {
    setMuaLe(true)
  }
  
  const clickMuasi= () => {
    setMuaLe(false)
  }
  
  const handleGotoCheckout = () => {
    navigate("/thanh-toan");
  }
  
  return (
    <div className="chon-gia">
      <div className="them-vao-gio">
        {!muaLe &&
          <div className="li-n-h-lh animate-fadeIn" onClick={handlePopup}>
            <div className="li-n-h-text">Liên hệ</div>
            <div className="li-n-h-count">50 lượt liên hệ</div>
          </div>
        }
        {muaLe &&
          <div className="li-n-h-wrapper" onClick={handleGotoCheckout}>
            <div className="li-n-h">Thêm vào giỏ</div>
          </div>
        }
        {
          muaLe && <>
            <div className="frame-2">
              <div className="text-wrapper-16">1</div>
            </div>
            <div className="text-wrapper-17">-</div>
            <div className="text-wrapper-18">+</div>
            <div className="text-wrapper-19">Số lượng</div>
          </>
        }
        
        {muaLe && <div className="text-wrapper-20 animate-fadeIn">20.000 VND</div>}
        {!muaLe && <div className="text-wrapper-20 animate-fadeIn">2.000.000-50.000.000VND</div>}
      </div>
      <div className="mua-si-opt" onClick={clickMuasi}>
      <p className="mua-s-t-mua-t-nh-t">
          <span className="text-wrapper-6">Mua sỉ </span>
          <span className="text-wrapper-21">(đặt mua ít nhất: </span>
          <span className="text-wrapper-22">5 tấn</span>
          <span className="text-wrapper-21">)</span>
        </p>
        <div className="div-2">
          {!muaLe && <div className="ellipse-2"></div>}
        </div>
      </div>
      <div className="mua-le-opt" onClick={clickMuale}>
        <div className="text-wrapper-23">Mua lẻ</div>
        <div className="div-2">
          {muaLe && <div className="ellipse-2"></div>}
        </div>
      </div>
    </div>
  );
};
